<template>
  <div class="container-fluid lv-container">
    <!-- <el-link class="pull-right" icon="el-icon-close" style="font-size: 18px;" @click="exitFullScreen()"></el-link> -->

    <div class="row mt-3">
      <div class="col-md-6">
        <div class="card lv-card">
          <div class="card-body">
            <div class="lv-card-container pt-2 pl-2">
              <h6 class="d-flex align-items-center text-primary">
                <i class="el-icon-user mr-2" />
                {{ getEngagedAppointments[0] || '...' }}
              </h6>

              <div class="lv-surgery">
                <h6>Surgery 1</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card lv-card">
          <div class="card-body">
            <div class="lv-card-container pt-2 pl-2">
              <h6 class="d-flex align-items-center text-primary">
                <i class="el-icon-user mr-2" />
                {{ getEngagedAppointments[1] || '...' }}
              </h6>

              <div class="lv-surgery">
                <h6>Surgery 2</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card lv-card">
          <div class="card-body">
            <div class="lv-card-container pt-2 pl-2">
              <h6 class="d-flex align-items-center text-primary">
                <i class="el-icon-user mr-2" />
                {{ getEngagedAppointments[2] || '...' }}
              </h6>

              <div class="lv-surgery">
                <h6>Surgery 3</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card lv-card">
          <div class="card-body">
            <div class="lv-card-container pt-2 pl-2">
              <h6 class="d-flex align-items-center text-primary">
                <i class="el-icon-user mr-2" />
                {{ getEngagedAppointments[3] || '...' }}
              </h6>

              <div class="lv-surgery">
                <h6>Surgery 4</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card lv-card">
          <div class="card-body">
            <div class="lv-card-container pt-2 pl-2">
              <h6 class="d-flex align-items-center text-primary">
                <i class="el-icon-user mr-2" />
                {{ nextEngagedAppointment || '...' }}
              </h6>

              <div class="lv-surgery">
                <h6>Next</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lv-footer pl-3">
      <router-link class="navbar-brand" to="/dashboard">
        <img class="n-logo" src="img/hope-logo.png" alt="" style="height: 10rem;" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getEngagedAppointments', 'nextEngagedAppointment']),
  },

  methods: {
    exitFullScreen() {
      this.$router.go(-1)
    },
  },
};
</script>

<style>
.font-48 {
  font-size: 48px;
}
</style>
