import client from './client';

export function getDoctors() {
  return client().get('doctors')
    .then(response => response.data);
}

export function createPatient(params) {
  return new Promise((resolve, reject) => {
    client().post('patients', params)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function updatePatient(id, params) {
  return new Promise((resolve, reject) => {
    client().put(`patients/${id}`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function getPatients(params) {
  return client().get('patients', { params })
    .then(response => response.data);
}

export function getPatient(id) {
  return client().get(`patients/${id}`)
    .then(response => response.data);
}

export function deletePatient(id) {
  return client().delete(`patients/${id}`)
    .then(response => response.data)
    .catch(error => error);
}

export function createAppointment(params) {
  return new Promise((resolve, reject) => {
    client().post('appointments.json', params)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function updateAppointment(id, params) {
  return new Promise((resolve, reject) => {
    client().put(`appointments/${id}`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function deleteAppointment(id) {
  return client().delete(`appointments/${id}`)
    .then(response => response.data)
    .catch(error => error);
}

export function getAppointments(params={}) {
  return client().get('appointments', { params })
    .then(response => response.data);
}

export function getFollowUpAppointments() {
  return client().get('appointments/follow_up_appointments')
    .then(response => response.data);
}

export function sendRemindersForTodayAppointments(params={}) {
  return client().get('appointments/send_reminders', { params })
    .then(response => response.data);
}

export function getAllDoctorAppointments(date) {
  return client().get(`doctors/appointments?date=${date}`)
    .then(response => response.data);
}

export function getMedications() {
  return client().get('medications')
    .then(response => response.data)
    .catch(error => reject(error));
}

export function getProcedures() {
  return client().get('procedures')
    .then(response => response.data)
    .catch(error => reject(error));
}

export function getDiagnoses() {
  return client().get('diagnoses')
    .then(response => response.data)
    .catch(error => reject(error));
}

export function getMedicalHistories() {
  return client().get('medical_histories')
    .then(response => response.data)
    .catch(error => reject(error));
}

export function createTreatment(params) {
  return new Promise((resolve, reject) => {
    client().post('treatments', params)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}

export function sendMessage(id, params) {
  return new Promise((resolve, reject) => {
    client().post(`patients/${id}/message`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
}
