<template>
  <div class="mt-4 mx-4">
    <div class="row message-stats">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <h5 class="info-title">Total Messages</h5>
                <p class="text-primary"><strong>12000</strong></p>
              </div>

              <div class="col-3 text-center m-auto">
                <i class="now-ui-icons ui-1_email-85 text-primary icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <h5 class="info-title">Used Messages</h5>
                <p class="text-warning"><strong>3000</strong></p>
              </div>

              <div class="col-3 text-center m-auto">
                <i class="now-ui-icons ui-1_send text-warning icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <h5 class="info-title">Remaining Messages</h5>
                <p class="text-danger"><strong>4000</strong></p>
              </div>

              <div class="col-3 text-center m-auto">
                <i class="now-ui-icons business_chart-pie-36 text-danger icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <card class="card-nav-tabs" header-classes="card-header-warning">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-8 form-inline">
              <label class="mr-2">Select Patient: </label>

              <el-autocomplete
                class="inline-input"
                popper-class="patients-autocomplete"
                v-model="patientName"
                :fetch-suggestions="searchPatients"
                :trigger-on-focus="false"
                @select="onPatientSelect"
                @clear="clearPatientId"
                prefix-icon="el-icon-search"
                size="medium"
                clearable
              >
                <template slot-scope="{ item }">
                  <div class="value">{{ item.value }}</div>
                  <div class="contact">{{ item.contact }}</div>
                </template>
              </el-autocomplete>
            </div>

            <div class="col-md-4 d-inline-flex justify-content-end">
              <el-button
                class="pull-right"
                type="primary"
                size="medium"
                plain
                :loading="isLoading"
                :disabled="patientId == '' || message == ''"
                @click="sendMessageToPatient()"
              >Send Message</el-button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="textarea-container mt-4">
                <el-input
                  type="textarea"
                  placeholder="Write a message..."
                  v-model="message"
                  maxlength="160"
                  rows="12"
                  show-word-limit
                ></el-input>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <el-table
            :data="tableData"
            height="400"
            header-cell-class-name="text-primary"
          >
            <el-table-column
              prop="name"
              label="Sent"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { Card, Button, DropDown, Checkbox } from '@/components'
import { getPatients, sendMessage } from '@/services/api'

export default {
  components: {
    Card,
    DropDown,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },

  data() {
    return {
      patientId: '',
      patientName: '',
      message: '',
      isLoading: false,
      tableData: [
        {
          name: 'Tom',
        }, {
          name: 'Tom',
        }, {
          name: 'Tom',
        }, {
          name: 'Tom',
        }, {
          name: 'Tom',
        }, {
          name: 'Tom',
        }, {
          name: 'Tom',
        }, {
          name: 'Tom',
        }, {
          name: 'Tom',
        }, {
          name: 'Tom',
        }, {
          name: 'Tom',
        }
      ],
    }
  },

  methods: {
    searchPatients(query, resolve) {
      getPatients({ q: { name_or_phone_cont: query } })
        .then(response => {
          let patients = query ? response.data.map(patient => ({ value: patient.attributes.name, id: patient.id, contact: patient.attributes.phone })) : []
          resolve(patients)
        })
    },
    onPatientSelect(patient) {
      this.patientId = patient.id
      this.patientName = patient.value
    },
    clearPatientId() {
      this.patientId = ''
    },
    sendMessageToPatient() {
      this.isLoading = true
      sendMessage(this.patientId, { message: this.message })
        .then(response => {
          this.isLoading = false
          if (response.success)
            this.$message({
              message: 'Message was sent successfully.',
              type: 'success'
            });
          this.resetFormData()
        })
        .catch(error => {
          this.isLoading = false
          this.$message({
            message: `${error.response.data.errors}`,
            type: 'error'
          });
        })
    },
    resetFormData() {
      this.patientId = ''
      this.patientName = ''
      this.message = ''
    },
  }
};
</script>
