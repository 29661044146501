<template>
  <div class="mt-4 mx-4">
    <div class="row">
      <div class="col-md-12">
        <el-card v-loading="isLoading" class="patients-list">
          <div slot="header" class="d-flex justify-content-end">
            <!-- Card Header Content -->
            <el-button type="primary" @click="exportContactsInVCards">Export Contacts in VCard</el-button>
          </div>
          <el-table
            :data="patients"
            stripe
            height="80vh"
            :row-key="patientRowKey"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="Patient #"
              width="120"
            />
            <el-table-column
              prop="registrationNumber"
              label="Mr #"
              width="120"
            />
            <el-table-column
              prop="x_ray_number"
              label="X-Ray"
              width="120"
            />
            <el-table-column
              prop="name"
              label="Name"
              width="180"
            />
            <el-table-column
              prop="phone"
              label="Phone"
              width="180"
            />
            <el-table-column
              prop="gender"
              label="Gender"
              width="120"
            />
            <el-table-column
              prop="age"
              label="Age"
              width="120"
            />

            <el-table-column
              align="right"
              width="180"
            >
              <template slot="header" slot-scope="scope">
                <el-input
                  clearable
                  v-model="search"
                  size="medium"
                  placeholder="Search patient name or phone"
                />
              </template>

              <template slot-scope="scope">
                <!-- <el-button type="text">Edit</el-button> -->
                <el-tooltip content="Edit Patient" placement="bottom">
                  <el-button
                    plain
                    circle
                    size="medium"
                    type="warning"
                    icon="el-icon-edit"
                    @click="editPatient(scope.$index, scope.row)"
                  ></el-button>
                </el-tooltip>

                <el-tooltip content="Patient Profile" placement="bottom">
                  <router-link 
                    :to="{ name: 'patients-show', params: { id: scope.row.id } }"
                    class="el-button el-button--primary el-button--medium is-circle is-plain el-tooltip"
                  >
                    <i class="el-icon-user"></i>
                  </router-link>
                </el-tooltip>

                <el-tooltip content="Patient Delete" placement="bottom">
                  <el-button
                    plain
                    circle
                    size="medium"
                    type="danger"
                    icon="el-icon-delete"
                    @click="deleteThisPatient(scope.$index, scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>

          <div class="d-flex justify-content-center mt-3">
            <el-pagination
              v-if="pagy"
              background
              layout="pager"
              :page-size="20"
              :total="pagy.count"
              @current-change="onCurrentPageChange"
            />
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getPatients, deletePatient } from '@/services/api'
import { read, utils, writeFileXLSX } from 'xlsx';
import JSZip from 'jszip';
import EventBus from '@/services/event-bus'
import moment from 'moment'

export default {
  data() {
    return {
      isLoading: false,
      patients: [],
      allPatients: [],
      search: '',
      pagy: null,
    }
  },

  created() {
    this.loadPatients()
  },

  mounted() {
    EventBus.$on('onPatientUpdate', (index, patient) => {
      this.patients[index].dob = patient.attributes.dob
      this.patients[index].name = patient.attributes.name
      this.patients[index].phone = patient.attributes.phone
      this.patients[index].x_ray_number = patient.attributes.x_ray_number
      this.patients[index].gender = patient.attributes.gender || ''
      this.patients[index].age = this.patientAge(patient.attributes.dob)
      this.patients[index].medicalHistory = patient.attributes.medical_history
      this.patients[index].registrationNumber = patient.attributes.registration_number
    })
  },

  methods: {
    loadPatients(params={}, isPagination=true) {
      this.isLoading = true
      params.with_pagy = isPagination
      getPatients(params)
      .then(response => {
        this.isLoading = false
        this.pagy = response.pagy
        if (!isPagination) {
          this.allPatients = response.data.map(patient => {
            return {
              id: patient.id,
              name: patient.attributes.name,
              phone: patient.attributes.phone,
            }
          })
          const vCardContent = this.generateVCardForPatients(this.allPatients);
          this.downloadFile(vCardContent, 'Hope Dental Centre - Patients Contacts.vcf');
        } else {
          this.patients = response.data.map(patient => {
            return {
              id: patient.id,
              dob: patient.attributes.dob,
              name: patient.attributes.name,
              phone: patient.attributes.phone,
              x_ray_number: patient.attributes.x_ray_number,
              gender: patient.attributes.gender || '',
              age: this.patientAge(patient.attributes.dob),
              medicalHistory: patient.attributes.medical_history,
              registrationNumber: patient.attributes.registration_number
            }
          })
        }
      })
    },
    patientAge(dob) {
      return dob != null ? `${moment().diff(dob, 'years', false)} Years` : ''
    },
    editPatient(index, patient) {
      EventBus.$emit('onAddPatientClick', true, 'edit', patient, index)
    },
    deleteThisPatient(index, patient) {
      if (window.confirm("Are you sure to delete this patient?")) {
        deletePatient(patient.id)
          .then(response => {
            this.patients.splice(index, 1)
            this.$message({ showClose: true, message: 'Patient deleted successfully.', type: 'success' });
          })
          .catch(error => {
            this.$message.error('Patient deleting failed.');
          })
      }
    },
    patientRowKey(row) {
      return `patient${row.id}`
    },
    onCurrentPageChange(nextPage) {
      this.loadPatients({ page: nextPage, q: { name_or_phone_cont: this.search } })
    },
    exportContactsInVCards() {
      this.loadPatients({}, false)
    },
    generateVCardForPatients(patients) {
      let vCardString = '';

      patients.forEach(patient => {
        vCardString += `BEGIN:VCARD\n`;
        vCardString += `VERSION:3.0\n`;
        vCardString += `FN:${patient.name}\n`;
        vCardString += `TEL:0${patient.phone}\n`;
        vCardString += `END:VCARD\n`;
      });

      return vCardString;
    },
    downloadFile(content, filename) {
      const blob = new Blob([content], { type: 'text/vcard' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
  },

  watch: {
    search: function (name) {
      this.loadPatients({ q: { name_or_phone_cont: name } })
    },
  },
}
</script>
