<template>
  <div class="treatment-form-container">
    <el-form :model="treatmentForm">
      <div class="row" style="min-height: 200px;">
        <div class="col-12">
          <div
            v-for="medication in treatmentForm.medications"
            :key="medication.key"
            class="d-flex align-items-center border-bottom-1"
          >
            <el-form-item label="Name" size="small">
              <el-select v-model="medication.id" @change="selectChangeListener" size="small" class="w-100">
                <el-option
                  v-for="(medication, index) in medicationList"
                  :key="`medication_${medication.id}`"
                  :label="medication.name"
                  :value="medication.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="No. of Days" size="small">
              <el-input-number v-model="medication.duration" @change="selectChangeListener" size="small" controls-position="right" :min="1" />
            </el-form-item>

            <el-form-item label="Per day" size="small">
              <el-select v-model="medication.frequency" @change="selectChangeListener" size="small" class="w-100">
                <el-option
                  v-for="(frequency, index) in frequencies"
                  :key="`frequency_${index}`"
                  :label="frequency"
                  :value="frequency"
                />
              </el-select>
            </el-form-item>

            <el-button type="danger" icon="el-icon-close" size="mini" round @click.prevent="removeMedication(medication)" />
          </div>

          <el-form-item label="Foot Note" class="w-100" size="small">
            <!-- Foot Note is a description -->
            <el-input v-model="treatmentForm.description" @change="selectChangeListener" type="textarea" :rows="2" clearable />
          </el-form-item>
          <span slot="footer" class="dialog-footer">
            <el-button class="pull-left" size="small" @click="addMedication()">Add Medication</el-button>
            <!-- <el-button class="pull-left" size="small" @click="applyChanges()">Apply</el-button> -->
          </span>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import EventBus from '@/services/event-bus'
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        treatmentForm: {
          description: '',
          medicationList: [],
          medications: [{
            key: new Date().getTime(),
            id: '',
            name: '',
            duration: '',
            frequency: '',
          }]
        },
        frequencies: ['1 - 0 - 0', '1 - 0 - 1', '1 - 1 - 1'],
      };
    },

    computed: {
      ...mapGetters(['getMedications']),
    },

    created() {
      this.medicationList = this.getMedications.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    },

    methods: {
      applyChanges() {
        EventBus.$emit('onMedicationsApplied', this.treatmentForm);
      },
      selectChangeListener() {
        this.applyChanges()
      },
      addMedication() {
        this.treatmentForm.medications.push({
          key: new Date().getTime(),
          id: '',
          name: '',
          duration: '',
          frequency: '',
        })
      },
      removeMedication(medication) {
        let index = this.treatmentForm.medications.indexOf(medication)
        if (index !== -1) {
          this.treatmentForm.medications.splice(index, 1)
          this.applyChanges()
        }
      },
    }
  };
</script>

<style lang="scss">
</style>
