<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" to="/">
      </router-link>
    </template>

    <template slot="navbar-menu">
      <drop-down
        tag="li"
        title="Account"
        icon="now-ui-icons users_single-02"
        class="nav-item"
      >
        <nav-link to="/">
          <i class="now-ui-icons users_single-02"></i> Home
        </nav-link>

        <nav-link to="/login">
          <i class="now-ui-icons users_circle-08"></i> Login
        </nav-link>
      </drop-down>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';

export default {
  name: 'main-navbar',

  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },

  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  }
};
</script>
