<template>
  <div id="printPrescriptionLayout" class="d-none">
    <table v-if="currentPatient" border="0" width="794" height="1123" cellpadding="0" cellspacing="0" bgcolor="ffffff" style="border: solid 1px #dbdbdb; width: 21cm; height: 29cm; font-style: 'Perpetua';">
      <tr>
        <td style="display: block; height: 9.6cm; padding: 0;">
          <div style="width: 8cm; height: 9.6cm;"></div>
        </td>
      </tr>

      <tr>
        <td style="height: 1cm; padding: 0; display: block;">
          <table border="0" align="left" width="794" cellpadding="0" cellspacing="0">
            <tr>
              <td style="padding: 10px; padding-left: 20px; color: #000; font-size: 14px; text-transform: uppercase; font-family: 'Perpetua';">
                <strong>{{ currentPatient.attributes.name }}</strong>
              </td>

              <td style="display: flex; color: #000; float: right; font-family: 'Perpetua';">
                <strong style="padding: 8px;">X-Ray: </strong>
                <span style="background-color: #fff; width: 100px; color: #000; float: right; padding: 8px; font-size: 12px; font-style: italic;">{{ currentPatient.attributes.x_ray_number || 'N/A' }}</span>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr style="display: flex;">
        <td width="302">
          <table border="0" align="left" width="302" cellpadding="0" cellspacing="0" style="display: block; height: 19cm;">
            <tr>
              <td>
                <table border="0" align="left" width="302" cellpadding="0" cellspacing="0" style="display: block; height: 4cm;">
                  <tr>
                    <td style="padding: 10px; padding-left: 20px; color: #000; display: block; font-size: 12px; text-transform: uppercase; font-family: 'Perpetua';">
                      <span>Medical History</span>
                    </td>
                  </tr>

                  <tr
                    v-for="(medicalHistory, index) in currentPatient.attributes.medical_history"
                    :key="`medicalHistory_${index}`"
                    style="display: flex;"
                  >
                    <td style="padding: 0 0 0 30px; color: #000; display: block; font-size: 11px; font-style: italic; font-family: 'Perpetua';">{{ medicalHistory }}</td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style="display: block;" width="302">
                <table border="0" align="left" width="302" cellpadding="0" cellspacing="0" style="display: block; height: 7.5cm;">
                  <tr>
                    <td style="padding: 10px; padding-left: 20px; color: #000; display: block; font-size: 12px; text-transform: uppercase; font-family: 'Perpetua';">
                      <span>Diagnosis</span>
                    </td>
                  </tr>

                  <!-- Teeth view in graphical xy axis view -->
                  <tr
                    v-for="(key, index) in Object.keys(prescriptionDiagnosesGroup)"
                    :key="`diagnosisRow_${index}`"
                    style="display: flex; margin-top: 8px;"
                  >
                    <td style="padding: 0 0 0 30px; color: #000; display: block; font-size: 11px; font-style: italic; font-family: 'Perpetua';">
                      <span style="font-weight: bold; margin-right: 8px;">{{ `${key}:` }}</span>
                      <span
                        v-for="(group, j) in teethSorting(prescriptionDiagnosesGroup[key])"
                        :key="`diagnosisRow_${index}_${j}`"
                        :style="Array.isArray(group) ? toothPreview(group) : ''"
                      >
                        {{ Array.isArray(group) ? group.map(item => item.substr(2)).join(' ') : group }}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td style="display: block;" width="302">
                <table border="0" align="left" width="302" cellpadding="0" cellspacing="0" style="display: block; height: 7.5cm;">
                  <tr>
                    <td style="padding: 0 10px 10px 10px; padding-left: 20px; color: #000; font-size: 12px; text-transform: uppercase; font-family: 'Perpetua';">
                      <span>Treatment</span>
                    </td>
                  </tr>

                  <!-- Teeth view in graphical xy axis view -->
                  <tr
                    v-for="(key, index) in Object.keys(prescriptionProceduresGroup)"
                    :key="`procedurRow_${index}`"
                    style="display: flex; margin-top: 8px;"
                  >
                    <td style="padding: 0 0 0 30px; color: #000; display: block; font-size: 11px; font-style: italic; font-family: 'Perpetua';">
                      <span style="font-weight: bold; margin-right: 8px;">{{ `${key}:` }}</span>
                      <span
                        v-for="(group, j) in teethSorting(prescriptionProceduresGroup[key])"
                        :key="`procedurRow_${index}_${j}`"
                        :style="Array.isArray(group) ? toothPreview(group) : ''"
                      >
                        {{ Array.isArray(group) ? group.map(item => item.substr(2)).join(' ') : group }}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>

        <td width="492">
          <table border="0" align="right" width="492" cellpadding="0" cellspacing="0" style="display: block; height: 18cm;">
            <tr>
              <td>
                <table border="0" align="right" width="492" cellpadding="0" cellspacing="0" style="height: 2cm;">
                  <tr>
                    <td style="padding: 10px; color: #000; width: 50%; font-size: 11px; font-style: italic;">
                      <span style="font-size: 12px; text-transform: uppercase; font-style: normal; font-family: 'Perpetua';">Age: </span>{{ `${patientAge}` }}
                    </td>
                  </tr>

                  <tr>
                    <td style="padding: 10px; color: #000; width: 50%; text-transform: capitalize; font-size: 11px; font-style: italic;">
                      <span style="font-size: 12px; text-transform: uppercase; font-style: normal; font-family: 'Perpetua';">Gender: </span>{{ currentPatient.attributes.gender }}
                    </td>

                    <td style="padding: 10px; color: #000; width: 50%; font-size: 11px; font-style: italic;">
                      <span style="font-size: 12px; text-transform: uppercase; font-style: normal; font-family: 'Perpetua';">CONTACT: </span>{{ currentPatient.attributes.phone }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr align="right">
              <td style="padding: 10px 20px 0px 10px; color: #000; width: 50%; font-size: 12px; font-style: italic; height: 0.75cm;">
                <span style="font-size: 12px; text-transform: uppercase; font-style: normal; font-family: 'Perpetua';">Date: </span>{{ currentDate }}
              </td>
            </tr>

            <tr>
              <td width="492">
                <table border="0" align="left" width="492" cellpadding="0" cellspacing="0" style="display: block; height: 16cm;">
                  <tr style="display: block; margin: 10px;">
                    <td style="padding: 10px; font-family: 'Perpetua'; text-transform: uppercase;">
                      <span>Medication</span>
                    </td>
                  </tr>

                  <tr
                    v-for="(prescriptionMedication, index) in prescription"
                    :key="`prescriptionRow_${index}`"
                    style="margin: 10px 10px 10px 40px; display: list-item; list-style-type: decimal; list-style-position: outside; font-size: 11px; font-style: italic;"
                  >
                    <td style="padding: 0 0 0 10px; display: inline-block; font-size: 12px; font-style: italic; font-family: 'Perpetua';">
                      {{ onelineMedication(prescriptionMedication) }}
                    </td>
                  </tr>

                  <!-- Showing Foot Note on Prescription -->
                  <tr style="display: block; margin: 10px;">
                    <td style="padding: 10px; font-family: 'Perpetua'; text-transform: uppercase;">
                      <span>Note</span>
                    </td>
                  </tr>
                  <tr style="margin: 10px 10px 10px 40px;">
                    <td style="padding: 0 0 0 30px; display: inline-block; font-size: 12px; font-style: italic; font-family: 'Perpetua';">
                      {{ treatmentForm.description }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>

import moment from 'moment'
import { reactive } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { getPatient, getMedications, getProcedures } from '@/services/api'

export default {
  name: 'Prescription',
  data() {
    return {
      currentPatient: null,
      treatmentForm: {
        advice: '',
        toothId: '',
        description: '',
        appointmentId: '',
        diagnoses: [],
        procedures: [],
        medications: [],
      },
      isOpen: false
    };
  },
  created() {
    getPatient(this.$route.params.id)
      .then(response => {
        this.currentPatient = response.data
      })
  },
  mounted() {
    // Listen for the emitted events from the parent component
    this.$parent.$on('treatmentFormUpdate', (treatmentForm) => {
      // Update the treatmentForm prop in the Prescription component
      this.treatmentForm = treatmentForm;
      this.$forceUpdate();

      // Use $nextTick to execute code after the component is re-rendered
      this.$nextTick(() => {
        this.printPrescription();
      });
    });
  },
  computed: {
    ...mapGetters(['getProcedures', 'getMedications', 'getDiagnoses']),
    currentDate() {
      return moment().format('DD-MM-yyyy')
    },
    patientAge() {
      let patientAge = moment().diff(this.currentPatient.attributes.dob, 'years', false)
      if (patientAge) {
        return `${patientAge} years`
      } else {
        return '0 years'
      }
    },
    prescription() {
      let medications = []
      this.treatmentForm.medications.forEach(treatmentMedication => {
        let index = this.getMedications.findIndex(medication => medication.id == treatmentMedication.id)
        if (index > -1) treatmentMedication.name = this.getMedications[index].name;

        let medication = treatmentMedication.attributes || treatmentMedication;
        medications.push({ medication: medication.name || medication.medication_name, duration: medication.duration, frequency: medication.frequency })
      })

      return medications
    },
    prescriptionDiagnoses() {
      let diagnoses = []
      this.treatmentForm.diagnoses.forEach(treatmentDiagnosis => {
        let index = this.getDiagnoses.findIndex(diagnosis => diagnosis.id == treatmentDiagnosis.id)
        if (index > -1) treatmentDiagnosis.name = this.getDiagnoses[index].name
        diagnoses.push({ name: treatmentDiagnosis.name, toothId: treatmentDiagnosis.toothId || treatmentDiagnosis.tooth_id })
      })

      return diagnoses
    },
    prescriptionProcedures() {
      let procedures = []
      this.treatmentForm.procedures.forEach(treatmentProcedure => {
        let index = this.getProcedures.findIndex(diagnosis => diagnosis.id == treatmentProcedure.id)
        if (index > -1) treatmentProcedure.name = this.getProcedures[index].name

        let procedure = treatmentProcedure.attributes || treatmentProcedure;
        procedures.push({ name: procedure.name || procedure.procedure_name, toothId: procedure.toothId || procedure.tooth_id })
      })

      return procedures
    },
    prescriptionDiagnosesGroup() {
      let diagnoses = this.prescriptionDiagnoses

      const groupedDaignoses = diagnoses.reduce((accumulator, currentDiagnosis) => {
          const { name, toothId } = currentDiagnosis;

          // If the name is not in the accumulator, create a new array
          if (!accumulator[name]) {
              accumulator[name] = [];
          }

          // Add the currentDiagnosis to the corresponding array
          accumulator[name].push(toothId);

          return accumulator;
      }, {});

      return groupedDaignoses;
    },
    prescriptionProceduresGroup() {
      let procedures = this.prescriptionProcedures

      const groupedProcedures = procedures.reduce((accumulator, currentProcedure) => {
          const { name, toothId } = currentProcedure;

          // If the name is not in the accumulator, create a new array
          if (!accumulator[name]) {
              accumulator[name] = [];
          }

          // Add the currentProcedure to the corresponding array
          accumulator[name].push(toothId);

          return accumulator;
      }, {});

      return groupedProcedures;
    },
  },
  methods: {
    onelineMedication(prescription) {
      let onelineMedication = []
      if (prescription.medication) onelineMedication.push(prescription.medication)
      if (prescription.frequency) onelineMedication.push(`(${prescription.frequency})`)
      if (prescription.duration) onelineMedication.push(`${prescription.duration} days`)
      return onelineMedication.join(', ')
    },
    teethSorting(teethArray) {
      // Sample arrays for testing purpose only
      // teethArray = ["RUB", "RLC", "RUF", "RUA", "RLA", "RLD", "LLG", "LUD", "LUA", "LLZ"]
      // teethArray = ["RU5", "RL6", "RU1", "RU3", "RL1", "RL7", "LL8", "LU6", "LU5", "LL9"]
      // teethArray = ["RU5", "RL6", "RU1", "RU3", "RL1", "RUB", "RLC", "RUF", "RUA", "RLA", "RLD", "LLG", "LUD", "LUA", "LLZ", "RL7", "LL8", "LU6", "LU5", "LL9"]

      // Create an object to store the groups on given structure
      const structure = ["RU", "LU", "RL", "LL"]
      const grouped = {};

      // Initialize the groups
      structure.forEach(group => {
        grouped[group] = [];
      });

      // Populate the groups
      teethArray.forEach(item => {
        structure.forEach(group => {
          if (item.startsWith(group)) {
            grouped[group].push(item);
          }
        });
      });

      // Sort each group
      for (const group in grouped) {
        if (group === "RU" || group === "RL") {
          // Descending order for "RU" and "RL"
          grouped[group].sort((a, b) => {
            if (/^\d+$/.test(a) && /^\d+$/.test(b)) {
              return parseInt(b, 10) - parseInt(a, 10); // Numerical descending
            }
            return b.localeCompare(a); // Alphanumeric descending
          });
        } else {
          // Ascending order for "LU" and "LL"
          grouped[group].sort((a, b) => {
            if (/^\d+$/.test(a) && /^\d+$/.test(b)) {
              return parseInt(a, 10) - parseInt(b, 10); // Numerical ascending
            }
            return a.localeCompare(b); // Alphanumeric ascending
          });
        }
      }

      // Create the final output as an array of arrays
      const outputArray = structure.map(group => grouped[group]);

      return this.determineArchType(outputArray);
    },
    determineArchType(inputArray) {
      const upperArchPattern = ["RU8", "RU7", "RU6", "RU5", "RU4", "RU3", "RU2", "RU1", "LU1", "LU2", "LU3", "LU4", "LU5", "LU6", "LU7", "LU8"]
      const lowerArchPattern = ["RL8", "RL7", "RL6", "RL5", "RL4", "RL3", "RL2", "RL1", "LL1", "LL2", "LL3", "LL4", "LL5", "LL6", "LL7", "LL8"]

      const upperArchChildPattern = ['RUE', 'RUD', 'RUC', 'RUB', 'RUA', 'LUA', 'LUB', 'LUC', 'LUD', 'LUE'];
      const lowerArchChildPattern = ['RLE', 'RLD', 'RLC', 'RLB', 'RLA', 'LLA', 'LLB', 'LLC', 'LLD', 'LLE'];

      const firstHalf = inputArray.slice(0, 2).flat();
      const secondHalf =  inputArray.slice(2).flat();
      let isUpperArchDetected = false;
      let isLowerArchDetected = false;
      let isUpperArchChildDetected = false;
      let isLowerArchChildDetected = false;
      let resultArray = [];

      if (firstHalf.length == upperArchPattern.length) {
        for (let i = 0; i < firstHalf.length; i++) {
          if (firstHalf[i] !== upperArchPattern[i]) {
            isUpperArchDetected = false;
            break;
          }
          isUpperArchDetected = true;
        }
      } else if (firstHalf.length == upperArchChildPattern.length) {
        for (let i = 0; i < firstHalf.length; i++) {
          if (firstHalf[i] !== upperArchChildPattern[i]) {
            isUpperArchChildDetected = false;
            break;
          }
          isUpperArchChildDetected = true;
        }
      }

      if (secondHalf.length == lowerArchPattern.length) {
        for (let i = 0; i < secondHalf.length; i++) {
          if (secondHalf[i] !== lowerArchPattern[i]) {
            isLowerArchDetected = false;
            break;
          }
          isLowerArchDetected = true;
        }
      } else if (secondHalf.length == lowerArchChildPattern.length) {
        for (let i = 0; i < secondHalf.length; i++) {
          if (secondHalf[i] !== lowerArchChildPattern[i]) {
            isLowerArchChildDetected = false;
            break;
          }
          isLowerArchChildDetected = true;
        }
      }

      // Check if the first array is an 'Upper Arch'
      if (isUpperArchDetected && isLowerArchDetected) {
        resultArray.push("Full Arch");
        return resultArray;
      } else if (isUpperArchDetected) {
        resultArray.push("Upper Arch", inputArray[2], inputArray[3]);
        return resultArray;
      } else if (isLowerArchDetected) {
        resultArray.push(inputArray[0], inputArray[1], "Lower Arch");
        return resultArray;
      } else if (isUpperArchChildDetected && isLowerArchChildDetected) {
        resultArray.push("Full Arch (C)");
        return resultArray;
      } else if (isUpperArchChildDetected) {
        resultArray.push("Upper Arch (C)", inputArray[2], inputArray[3]);
        return resultArray;
      } else if (isLowerArchChildDetected) {
        resultArray.push(inputArray[0], inputArray[1], "Lower Arch (C)");
        return resultArray;
      }

      return inputArray;
    },
    toothPreview(teethArray) {
      let borderWidthClass = ""

      if (teethArray.length <= 0) {
        return reactive({display: 'none'})
      }

      const label = teethArray[0].substring(0, 2)
      if (label.includes('RU')) {
        borderWidthClass = "0px 1px 1px 0px"
      } else if(label.includes('LU')) {
        borderWidthClass = "0px 0px 1px 1px"
      } else if(label.includes('RL')) {
        borderWidthClass = "1px 1px 0px 0px"
      } else if(label.includes('LL')) {
        borderWidthClass = "1px 0px 0px 1px"
      }

      const styleObject = reactive({
        display: 'inline-flex',
        padding: '2px',
        margin: '2px',
        borderStyle: 'solid',
        color: '#000',
        borderWidth: borderWidthClass
      })

      return styleObject
    },
    printPrescription() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('printPrescriptionLayout').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      // for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
      //   stylesHtml += node.outerHTML;
      // }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
          <link rel="preconnect" href="https://fonts.gstatic.com">
          <style>
            body {
              margin: 0;
              padding: 0;
            }
            table, tr, td {
              font-family: 'Perpetua' !important;
            }
            @media print {
              @page {
                size: A4 portrait;
                margin: 0;
              }
            }
          </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();

    },
  }
};
</script>
