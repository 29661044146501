<template>
  <div class="row">
    <div class="col-md-8">
      <FullCalendar :eventLimit="3" :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  name: "IndexAvailability",

  components: {
    FullCalendar
  },

  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        dayMaxEvents: true,
        views: {
          dayGridMonth: {
            titleFormat: { year: "numeric", month: "short" }
          }
        },
        dateClick: this.handleDateClick,
        events: []
      }
    };
  },

  methods: {
    handleDateClick(arg) {
      if (!moment(arg.date).isBefore(moment(), "day")) {
        this.modal = true;
        this.availability.date = arg.dateStr;
      } else {
        this.addFixedToast();
      }
    },
  }
};
</script>

<style lang="sass" scoped>
.toast
  background-color: #d9534f
  color: white
</style>
