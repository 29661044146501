<template>
  <div class="mt-4 mx-4">
    <div class="row">
      <div class="col-md-12">
        <el-card v-loading="isLoading" class="patients-list">
          <div class="form-inline">
            <label class="mr-2">Doctor: </label>
            <el-select v-model="doctorFilter" @change="doctorAppointments()" placeholder="Select Doctor" size="small">
              <el-option-group
                v-for="doctorCategory in doctorsList"
                :key="doctorCategory.label"
                :label="doctorCategory.label">
                <el-option
                  v-for="doctor in doctorCategory.doctors"
                  :key="doctor.value"
                  :label="doctor.label"
                  :value="doctor.value">
                </el-option>
              </el-option-group>
            </el-select>
          </div>
          <el-table
            :data="appointmentRequests.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
            stripe
            height="80vh"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="Patient #"
              width="180"
            />
            <el-table-column
              prop="name"
              label="Name"
              width="180"
            />
            <el-table-column
              prop="phone"
              label="Phone"
              width="180"
            />
            <el-table-column
              prop="doctor"
              label="Doctor"
              width="180"
            />
            <el-table-column
              prop="gender"
              label="Gender"
              width="180"
            />
            <el-table-column
              prop="age"
              label="Age"
              width="180"
            />
            <el-table-column
              prop="date"
              label="Date"
              width="180"
            />
            <el-table-column
              prop="from"
              label="From"
              width="180"
            />

            <el-table-column
              align="right"
              width="180"
            >
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="search"
                  size="mini"
                  placeholder="Type patient name to search"/>
              </template>

              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  :loading="scope.row.deleting"
                  @click="rejectAppointment(scope.$index, scope.row)"
                >Delete</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getDoctors, getAppointments, updateAppointment, deleteAppointment } from '@/services/api'
import moment from 'moment'

export default {
  data() {
    return {
      isLoading: false,
      appointmentRequests: [],
      search: '',
      doctorFilter: 'all',
      doctorsList: [{
        label: 'Dentist',
        doctors: [],
      }],
    }
  },

  created() {
    this.isLoading = true
    getAppointments({ q: { status_eq: 'request' } })
      .then(response => {
      	console.log(response)
        this.isLoading = false
        this.appointmentRequests = response.data.map(appointment => {
          let doctor_name = ""
          if (appointment.attributes.patient.data.attributes.doctor.data) {
            doctor_name = appointment.attributes.patient.data.attributes.doctor.data.attributes.name
          }

          return {
          	updating: false,
          	deleting: false,
            id: appointment.id,
            name: appointment.attributes.patient.data.attributes.name,
            phone: appointment.attributes.patient.data.attributes.phone,
            doctor: doctor_name,
            gender: appointment.attributes.patient.data.attributes.gender || '-',
            age: this.patientAge(appointment.attributes.patient.data.attributes.dob),
            date: moment(appointment.attributes.appointment_on).format('DD-MM-yyyy'),
            from: moment(appointment.attributes.from).format('hh:mm a'),
          }
        })
      })

    getDoctors()
      .then(response =>{
        this.doctorsList[0].doctors.push({ label: 'All', value: 'all' });
        response.data.forEach(doctor => {
          this.doctorsList[0].doctors.push({ label: doctor.attributes.name, value: doctor.id })
        })
      })
  },

  methods: {
    patientAge(dob) {
      return dob != null ? `${moment().diff(dob, 'years', false)} Years` : '-'
    },
    doctorAppointments() {
      this.isAppointmentsLoading = true
      let params = { q: { status_eq: 'request' }, doctor_id: this.doctorFilter }
      getAppointments(params)
        .then(response => {
          console.log(response)
          this.isLoading = false
          this.appointmentRequests = []
          this.appointmentRequests = response.data.map(appointment => {
            let doctor_name = ""
            if (appointment.attributes.patient.data.attributes.doctor.data) {
              doctor_name = appointment.attributes.patient.data.attributes.doctor.data.attributes.name
            }

            return {
              updating: false,
              deleting: false,
              id: appointment.id,
              name: appointment.attributes.patient.data.attributes.name,
              phone: appointment.attributes.patient.data.attributes.phone,
              doctor: doctor_name,
              gender: appointment.attributes.patient.data.attributes.gender || '-',
              age: this.patientAge(appointment.attributes.patient.data.attributes.dob),
              date: moment(appointment.attributes.appointment_on).format('DD-MM-yyyy'),
              from: moment(appointment.attributes.from).format('hh:mm a'),
            }
          })
        })
    },
    acceptAppointment(index, appointment) {
      appointment.updating = true
      updateAppointment(appointment.id, { appointment: { status: 'confirmed' } })
        .then(response => {
          this.appointmentRequests.splice(index, 1)
        })
        .catch(error => {
          appointment.updating = true
          console.log(error)
        })
    },
    rejectAppointment(index, appointment) {
      appointment.deleting = true
      deleteAppointment(appointment.id)
        .then(response => {
          appointment.deleting = false
          this.appointmentRequests.splice(index, 1)
        })
        .catch(error => {
          appointment.deleting = false
          console.log(error)
        })
    },
  },
}
</script>
