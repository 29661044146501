<template>
  <div class="page-header clear-filter" filter-color="blue">
    <div class="page-header-image" style="background-image: url('img/dental-login.jpg')"></div>

    <div class="content">
      <div class="container">
        <div class="col-md-6 ml-auto mr-auto">
          <card type="login" plain style="min-width: fit-content;">
            <div slot="header">
              <div class="d-flex align-items-center justify-content-center logo-container mb-4">
                <img class="n-logo mb-0" src="img/logo.jpg" alt="" />
              </div>

              <h2 class="h1-seo text-white">Hope Dental Centre.</h2>
            </div>

            <el-input
              v-model="email"
              class="no-border input-lg login-input mb-4"
              prefix-icon="now-ui-icons users_circle-08"
              placeholder="Email..."
            >
            </el-input>

            <el-input
              v-model="password"
              class="no-border input-lg login-input mb-4"
              prefix-icon="now-ui-icons ui-1_lock-circle-open"
              placeholder="Password..."
              type="password"
            >
            </el-input>

            <el-button round type="primary" class="btn-lg btn-block" :loading="isLoading" @click="onDoctorLogin()">Login</el-button>

            <template slot="raw-content">
              <div class="pull-left">
                <h6>
                  <a href="#" class="link footer-link d-none">Create Account</a>
                </h6>
              </div>

              <div class="pull-right">
                <h6>
                  <a href="#" class="link footer-link d-none">Need Help?</a>
                </h6>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container h-100">
        <div class="d-flex align-items-center justify-content-end h-100">
          <div class="copyright">
            &copy; {{ year }}, Created by
            <a href="#">DeveloperStack</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { Card, FormGroupInput } from '@/components'
import MainFooter from '@/layout/MainFooter'
import EventBus from '@/services/event-bus'
import { mapActions } from 'vuex'

export default {
  name: 'login-page',

  bodyClass: 'login-page',

  components: {
    Card,
    MainFooter,
    [FormGroupInput.name]: FormGroupInput
  },

  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      year: new Date().getFullYear(),
    }
  },

  methods: {
    ...mapActions(['createSession']),
    onDoctorLogin() {
      if (this.email == '' || this.password == '')
        this.$message({ showClose: true, message: 'Invalid credentials.', type: 'error' });
      else {
        this.isLoading = true
        this.createSession({ email: this.email, password: this.password })
          .then(response => {
            this.isLoading = false
            EventBus.$emit('onSuccessfulLogin', true)
            this.$router.push('/dashboard')
          })
          .catch(error => {
            this.isLoading = false
            this.$message({ showClose: true, message: 'Invalid email or password.', type: 'error' })
          })
      }
    },
  },
};
</script>

<style>
  .login-input input {
    background-color: hsla(0,0%,100%,.1);
    color: #fff !important;
    padding: 15px 19px;
    border-left: 0 none;
    border: medium none;
    border-color: hsla(0,0%,100%,.5);
    border-top-left-radius: 0;
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-radius: 30px;
    color: #2c2c2c;
    line-height: normal;
    font-size: .8571em;
    height: 100%;
    transition: color .3s ease-in-out,border-color .3s ease-in-out,background-color .3s ease-in-out;
    box-shadow: none;
  }
</style>
