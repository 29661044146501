import axios from 'axios';
import Qs from 'qs';
import store from '@/store/index';
import NProgress from 'nprogress/nprogress';

// export const API_URL = 'http://localhost:3002/api/v1/';
export const API_URL = 'https://backend.hopedentalcentre.org/api/v1/';
// export const API_URL = 'http://localhost:3000/api/v1/';
export const AUTH_TOKEN = 'AUTH-TOKEN';

function requestHeaders() {
  return {
    'Content-Type': 'application/json',
    'AUTH-TOKEN': getAuthToken(),
  };
}

function getAuthToken() {
  return store.getters.getToken;
}

// before a request is made start the nprogress
axios.interceptors.request.use(config => {
  NProgress.start();
  return config;
});

// intercepting the response in order to handle error
axios.interceptors.response.use((response) => {
    NProgress.done();
    return response;
  }, (error) => {
    if (error.response.status === 401) {
      if (error.response && error.response.data)
        return Promise.reject(error.response.data);
      return Promise.reject(error.message);
    } else {
      if (error.response && error.response.data)
        return Promise.reject(error.response.data);
      return Promise.reject(error.message);
    }
  });

const client = (apiURL = API_URL) =>
  axios.create({
    baseURL: apiURL,
    headers: requestHeaders(),
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets' }),
  });

export default client;
