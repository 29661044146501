<template>
  <navbar type="primary" class="mb-0">
    <template>
      <router-link class="navbar-brand" to="/dashboard">
        <img src="img/small-logo.png" alt="logo" height="60px">
      </router-link>
    </template>

    <template slot="navbar-menu">
      <li class="nav-item">
        <a class="nav-link cursor-pointer" @click="showPatientForm">
          <i class="now-ui-icons ui-1_simple-add"></i>
          <p>Patient</p>
        </a>
      </li>

      <drop-down
        tag="li"
        :title="currentUserName"
        icon="now-ui-icons users_single-02"
        class="nav-item"
      >
        <a class="dropdown-item cursor-pointer" @click="goToAdminPanel()">Admin Panel</a>
        <a class="dropdown-item cursor-pointer" @click="logout">Logout</a>
      </drop-down>
    </template>

    <form slot="before-menu" class="form-inline mx-auto" data-background-color>
      <div class="d-none">
        <fg-input addon-left-icon="now-ui-icons ui-1_zoom-bold" placeholder="Search..." inputClasses="search-input w-350"></fg-input>
      </div>
    </form>
  </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink, FormGroupInput as FgInput } from '@/components'
import { Popover } from 'element-ui'
import { mapActions, mapGetters } from 'vuex'
import EventBus from '@/services/event-bus'
import { createAdminPanelSession } from '@/services/api'

export default {
  name: 'main-navbar',

  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover,
    FgInput,
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
    currentUserName() {
      return this.isLoggedIn ? this.currentUser.attributes.name : 'User'
    },
  },

  methods: {
    ...mapActions(['clearSession']),
    showPatientForm() {
      EventBus.$emit('onAddPatientClick', true, 'new')
    },
    logout() {
      this.clearSession()
        .then(() => this.$router.push('/login'))
    },
    goToAdminPanel() {
      window.open('https://backend.hopedentalcentre.org/admin');
    },
  },
};
</script>

<style lang="scss">
  .w-350 {
    width: 300px !important;
  }
  .form-inline {
    .input-group {
      margin-bottom: 0;
    }
  }
</style>
