import Vue from 'vue';
import Vuex from 'vuex';
import client from '@/services/client';
import createPersistedState from 'vuex-persistedstate';
import { getProcedures, getMedications, getDiagnoses } from '@/services/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
    procedures: [],
    medications: [],
    diagnoses: [],
    engagedAppointments: [],
    nextAppointment: '',
  },

  getters: {
    isLoggedIn(state) {
      return !!state.currentUser;
    },

    currentUser(state) {
      return state.currentUser;
    },

    getToken(state) {
      return state.currentUser ? state.currentUser.attributes.auth_token : '';
    },

    getProcedures(state) {
      return state.procedures;
    },

    getMedications(state) {
      return state.medications;
    },

    getDiagnoses(state) {
      return state.diagnoses;
    },

    getEngagedAppointments(state) {
      return state.engagedAppointments;
    },

    nextEngagedAppointment(state) {
      return state.nextAppointment;
    },
  },

  mutations: {
    updateCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },

    updateProcedures(state, procedures) {
      state.procedures = procedures;
    },

    updateMedications(state, medications) {
      state.medications = medications;
    },

    updateDiagnoses(state, diagnoses) {
      state.diagnoses = diagnoses;
    },

    updateEngagedAppointments(state, { index, patientName }) {
      state.engagedAppointments[index] = patientName;
    },

    updateNextAppointment(state, patientName) {
      state.nextAppointment = patientName;
    },
  },

  actions: {
    createSession({ commit }, params) {
      return new Promise((resolve, reject) => {
        client().post('sessions', params)
          .then(response => {
            commit('updateCurrentUser', response.data.data);
            resolve(response.data);
          })
          .catch(error => reject(error));
      });
    },

    clearSession({ commit }) {
      return new Promise((resolve, reject) => {
        commit('updateCurrentUser', null);
        resolve();
      });
    },

    async loadProcedures({ commit }) {
      return await getProcedures()
        .then(response => commit('updateProcedures', response))
        .catch(error => console.log(error));
    },

    async loadMedications({ commit }) {
      return await getMedications()
        .then(response => commit('updateMedications', response))
        .catch(error => console.log(error));
    },

    async loadDiagnoses({ commit }) {
      return await getDiagnoses()
        .then(response => commit('updateDiagnoses', response))
        .catch(error => console.log(error));
    },

    engagePatient({ commit }, { index, patientName }) {
      commit('updateEngagedAppointments', { index, patientName });
    },

    engageNextPatient({ commit }, patientName) {
      commit('updateNextAppointment', patientName);
    },
  },

  plugins: [createPersistedState()],
});
