<template>
  <div id="app">
    <router-view name="header" />
    <router-view name="menubar" />

    <div class="px-4 pt-2" v-if="loginAlert">
      <el-alert
        title="You are successfully logged in!"
        type="success"
        show-icon
        @close="removeAlertContainer()"
      ></el-alert>
    </div>

    <div class="wrapper">
      <router-view />
    </div>

    <router-view name="footer" />

    <el-dialog :title="formType == 'new' ? 'Add patient' : 'Edit patient'" :visible.sync="showPatientForm" width="30%" style="margin: auto;">
      <el-form label-position="left" label-width="130px" :model="patientForm">
        <el-form-item label="Name" required>
          <el-input v-model="patientForm.name" size="small" prefix-icon="el-icon-user" />
        </el-form-item>

        <el-form-item label="Phone" required>
          <div class="d-flex">
            <div class="flex-fill">
              <el-input value="+92" size="small" :disabled="true" prefix-icon="el-icon-mobile-phone" />
            </div>

            <el-input v-model="patientForm.phone" size="small" type="tel" maxlength="10" />
          </div>
        </el-form-item>

        <el-form-item label="X-Ray">
          <el-input v-model="patientForm.x_ray_number" size="small" prefix-icon="el-icon-data-analysis" />
        </el-form-item>

        <el-form-item label="Gender" required>
          <el-radio-group v-model="patientForm.gender" size="small">
            <el-radio-button label="male">Male</el-radio-button>
            <el-radio-button label="female">Female</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="Date of birth" required>
          <el-date-picker type="date" placeholder="Pick a date" v-model="patientForm.dob" size="small" class="w-100" />
        </el-form-item>

        <el-form-item label="Medical History" class="w-100" size="small">
          <el-select v-model="patientForm.medical_history" multiple size="small" class="w-100">
            <el-option
              v-for="medicalHistoryOption in medicalHistoryOptions"
              :key="medicalHistoryOption"
              :label="medicalHistoryOption"
              :value="medicalHistoryOption"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" plain @click="closePatientForm()">Discard</el-button>
        <el-button size="small" type="primary" @click="savePatient()">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createPatient, updatePatient, getMedicalHistories } from '@/services/api'
import EventBus from '@/services/event-bus'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      loginAlert: false,
      showPatientForm: false,
      medicalHistoryOptions: [],
      patientForm: {
        id: null,
        name: '',
        phone: '',
        x_ray_number: '',
        gender: '',
        dob: '',
        medical_history: [],
      },
      formType: 'new',
      editIndex: -1,
    }
  },

  created() {
    getMedicalHistories()
      .then(response => {
        this.medicalHistoryOptions = response.map(medicalHistory => medicalHistory.name)
      })
  },

  mounted() {
    EventBus.$on('onSuccessfulLogin', (isSuccessfulLogin) => {
      this.loginAlert = true

      let scope = this
      setTimeout(() => { scope.loginAlert = false }, 2000)
    })

    EventBus.$on('onAddPatientClick', (visiblity, formType, patient=null, editIndex=-1) => {
      this.showPatientForm = visiblity
      this.formType = formType
      if (patient) {
        this.patientForm.id = patient.id
        this.patientForm.name = patient.name
        this.patientForm.phone = patient.phone
        this.patientForm.x_ray_number = patient.x_ray_number
        this.patientForm.gender = patient.gender
        this.patientForm.dob = patient.dob
        this.patientForm.medical_history = patient.medicalHistory
        this.editIndex = editIndex
      }
    })
  },

  computed: {
    ...mapGetters(['isLoggedIn']),
  },

  methods: {
    removeAlertContainer() {
      this.loginAlert = false
    },
    closePatientForm() {
      this.showPatientForm = false
      this.editIndex = -1
      this.formType = 'new'

      this.patientForm = {
        name: '',
        phone: '',
        x_ray_number: '',
        gender: '',
        dob: '',
      }
    },
    savePatient() {
      if (this.patientForm.id) {
        updatePatient(this.patientForm.id, { patient: this.patientForm })
          .then(response => {
            this.closePatientForm()
            this.$message({
              showClose: true,
              message: 'The patient was saved successfully.',
              type: 'success'
            });
            EventBus.$emit('onPatientUpdate', this.editIndex, response.data)
          })
          .catch(error => console.log(error.message))
      } else
        createPatient({ patient: this.patientForm })
          .then(response => {
            this.closePatientForm()
            this.$message({
              showClose: true,
              message: 'The patient was saved successfully.',
              type: 'success'
            });
          })
          .catch(error => console.log(error.message))
    },
  },
};
</script>
