<template>
  <footer class="ftco-footer ftco-bg-dark ftco-section">
    <div class="container" style="font-family: 'Poppins', Arial, sans-serif;font-size: 14px;line-height: 1.8;font-weight: 400;color: rgba(0, 0, 0, 0.6);">
      <div class="row mb-5">
        <div class="col-md">

          <div class="ftco-footer-widget mb-5">
            <h2 class="ftco-heading-2"></h2>
            <div class="block-23 mb-3">
              <ul>
                <li>
                  <i class="el-icon-location text-white mr-4"></i>
                  <span class="text">11 Chenab Market, Susan Road, Block Z Madina Town, Faisalabad, Punjab , Pakistan</span>
                </li>

                <li>
                  <a href="#">
                    <i class="el-icon-phone text-white mr-4"></i>
                    <span class="text">whatsapp 030-1111-HOPE( 4673)</span>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <i class="el-icon-phone text-white mr-4"></i>
                    <span class="text">041-8710046,041-8732531</span>
                  </a>
                </li>
              </ul>
            </div>

            <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
              <li><a href="https://www.facebook.com/hopedentalcentre.org/"><span class="icon-facebook"></span></a></li>
              <li><a href="https://www.instagram.com/hopedentalcentre/"><span class="icon-instagram"></span></a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-center">
          <p><strong>Copyright &copy; {{ new Date().getFullYear() }} All rights reserved | Hope Dental Centre</strong></p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>

