<template>
  <div class="mt-4 mx-4">
    <div class="row message-stats">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <h5 class="info-title">Total Appointments</h5>
                <h3 class="text-success m-0">{{ totalAppointments }}</h3>
              </div>

              <div class="col-3 text-center m-auto">
                <i class="now-ui-icons el-icon-tickets text-success icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <h5 class="info-title">Registered Patients</h5>
                <h3 class="text-primary m-0">{{ totalPatients }}</h3>
              </div>

              <div class="col-3 text-center m-auto">
                <i class="now-ui-icons el-icon-user text-primary icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <h5 class="info-title">Patients Visited Today</h5>
                <h3 class="text-warning m-0">0</h3>
              </div>

              <div class="col-3 text-center m-auto">
                <i class="now-ui-icons el-icon-user-solid text-warning icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <h5 class="info-title">Pending Dues</h5>
                <h3 class="text-secondary m-0">100</h3>
              </div>

              <div class="col-3 text-center m-auto">
                <i class="now-ui-icons el-icon-s-finance text-secondary icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters(['currentUser', 'isLoggedIn']),
      totalAppointments() {
        return this.currentUser.attributes.stats?.appointments ?? 8741
      },
      totalPatients() {
        return this.currentUser.attributes.stats?.patients ?? 2986
      },
    },
  }
</script>
