import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/views/Index';
import Dashboard from '@/views/dashboard';
import Requests from '@/views/requests';
import Appointments from '@/views/appointments';
import LiveView from '@/views/live-view';
import Patients from '@/views/patients/index';
import PatientShow from '@/views/patients';
import Messages from '@/views/messages';
import Calendar from '@/views/calendar';
import Login from '@/views/Login';
import Profile from '@/views/Profile';
import MainNavbar from '@/layout/MainNavbar';
import MainFooter from '@/layout/MainFooter';
import DashboardNavbar from '@/layout/dashboard-navbar';
import DashboardMenubar from '@/layout/dashboard-menubar';
import DashboardFooter from '@/layout/dashboard-footer';
import NProgress from 'nprogress/nprogress';
import store from '@/store/index';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'landing',
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      components: {
        default: Dashboard,
        header: DashboardNavbar,
        menubar: DashboardMenubar,
        footer: DashboardFooter,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/requests',
      name: 'requests',
      components: {
        default: Requests,
        header: DashboardNavbar,
        menubar: DashboardMenubar,
        footer: DashboardFooter,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/appointments',
      name: 'appointments',
      components: {
        default: Appointments,
        header: DashboardNavbar,
        menubar: DashboardMenubar,
        footer: DashboardFooter,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/live-view',
      name: 'live-view',
      components: { default: LiveView },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/patients',
      name: 'patients',
      components: {
        default: Patients,
        header: DashboardNavbar,
        menubar: DashboardMenubar,
        footer: DashboardFooter,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/patients/:id?',
      name: 'patients-show',
      components: {
        default: PatientShow,
        header: DashboardNavbar,
        menubar: DashboardMenubar,
        footer: DashboardFooter,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/messages',
      name: 'messages',
      components: {
        default: Messages,
        header: DashboardNavbar,
        menubar: DashboardMenubar,
        footer: DashboardFooter,
      },
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: '/calendar',
      name: 'calendar',
      components: {
        default: Calendar,
        header: DashboardNavbar,
        menubar: DashboardMenubar,
        footer: DashboardFooter,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      },
      meta: {
        requiresVisitor: true,
      },
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn)
      next({ name: 'login' });
    else
      next();
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.isLoggedIn) {
      next({ name: 'dashboard' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
