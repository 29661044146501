<template>
  <el-menu :default-active="defaultActiceTab" class="d-flex justify-content-center flex-wrap" mode="horizontal" active-text-color="#00AEF0" :router="true" text-color="#000">
    <el-menu-item index="/dashboard" class="mx-2">Dashboard</el-menu-item>
    <el-menu-item index="/requests" class="mx-2">Requests</el-menu-item>
    <el-menu-item index="/appointments" class="mx-2">Appointments</el-menu-item>
    <el-menu-item index="/patients" class="mx-2">Patients</el-menu-item>
    <el-menu-item index="/messages" class="mx-2">Messages</el-menu-item>
  </el-menu>
</template>

<script>
export default {
  computed: {
    defaultActiceTab() {
      let currentRoute = this.$route.name
      switch (currentRoute) {
        case 'requests':
          return '/requests'
          break;
        case 'appointments':
          return '/appointments'
          break;
        case 'patients' || 'patients-show':
          return '/patients'
          break;
        case 'patients-show':
          return '/patients'
          break;
        case 'messages':
          return '/messages'
          break;
        default:
          return '/dashboard'
          break;
      }
    }
  },
};
</script>
