<template>
  <div class="home">
    <div>
      <div class="py-md-5 py-4 border-bottom" style="background-color:#00898C">
        <div class="container">
          <div class="row no-gutters d-flex align-items-start align-items-center px-3 px-md-0">
            <div class="col-md-4 order-md-2 mb-2 mb-md-0 align-items-center text-center">
              <img src="img/logo.jpg" width="300px" height="200px">
            </div>

            <div class="col-md-4 order-md-1 d-flex topper mb-md-0 mb-2 align-items-center text-md-right">
              <a href="https://www.google.com/maps/place/Hope+Dental+Centre+%7C+AHR+%26+Assoc./@31.421307,73.119036,14z/data=!4m12!1m6!3m5!1s0x0:0xa499326b0c3ec22a!2sHope+Dental+Centre+%7C+AHR+%26+Assoc.!8m2!3d31.4201351!4d73.1170617!3m4!1s0x0:0xa499326b0c3ec22a!8m2!3d31.4201351!4d73.1170617?hl=en"><div class="icon d-flex justify-content-center align-items-center order-md-last">
                <span class="icon-map"></span>
              </div>
            </a>
              <div class="pr-md-4 pl-md-0 pl-3 text text-white">
                <p class="con">
                  <span>Call us</span>
                  <span>030-1111-(HOPE) 4673</span>
                </p>
                <p class="con" style="font-family: 'Poppins', Arial, sans-serif;margin-bottom: 0;font-weight: 400;">11 Chenab Market, Susan Road, Block Z Madina Town, Faisalabad, Punjab</p>
              </div>
            </div>

            <div class="col-md-4 order-md-3 d-flex topper mb-md-0 align-items-center">
              <div class="icon d-flex justify-content-center align-items-center"><span class="icon-calendar"></span></div>
              <div class="text pl-3 pl-md-3">
                <p class="hr"><span>Open Hours</span></p>
                <p class="time" style="color:white"><span>Sat - Thu:</span> <span style="color:white">10:00am - 06:00pm</span></p>
                <p class="time" style="color:white">Fri: Closed</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <el-carousel arrow="never" height="600px">
          <el-carousel-item>
            <div class="h-100" style="background-image:url(img/Slider1.jpg);background-repeat: no-repeat;background-size: cover;">
              <div class="container h-100">
                <div class="row no-gutters align-items-center justify-content-end h-100">
                  <div class="col-md-6">
                    <h1 class="mb-4">Affordable Reliable Credible</h1>
                    <h3 class="subheading text-dark">Our purpose built state of the art dental facility is well equiped to handle all your smile needs with a comfortable waiting area to match our world class services.</h3>
                    <p><a href="#book" class="btn btn-success btn-round px-4 py-3 mt-3" style="background: #a8c337;border: 1px solid #a8c337;color: #fff;font-size: 1rem;">Book Now</a></p>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>

          <el-carousel-item>
            <div class="h-100" style="background-image:url(img/slider2.jpg);background-repeat: no-repeat;background-size: cover;">
              <div class="container h-100">
                <div class="row no-gutters align-items-center justify-content-end h-100">
                  <div class="col-md-6 text">
                    <h1 class="mb-4">International Disinfection Protocol</h1>
                    <h3 class="subheading">We follow international disinfection and sterilization protocols set by American Dental Association and Royal college of Surgeons in England</h3>
                    <p><a href="#book" class="btn btn-success btn-round px-4 py-3 mt-3" style="background: #a8c337;border: 1px solid #a8c337;color: #fff;font-size: 1rem;">Book Now</a></p>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </section>

      <section class="container" style="min-height: 555px;">
        <div class="row no-gutters">
          <div class="col-md-5 p-md-5 img img-2 mt-5 mt-md-0" style="background-image: url(img/why-us.jpg);"></div>

          <div class="col-md-7 wrap-about py-4 py-md-5">
            <div class="heading-section mb-5">
              <div class="pl-md-5 ml-md-5 pt-md-5">
                <span class="subheading mb-2">Why Us?</span>
                <h2 class="mb-2" style="font-size: 32px;">Why are we the best Dental Clinic in Faisalabad with the best Dentists?</h2>
              </div>
            </div>

            <!-- <vue-aos animation-class="fadeIn animated"> -->
              <div class="pl-md-5 ml-md-5 mb-5">
                <p>Established in 1980 with over 40 years of experience, we pride ourselves as dental health providers for thousands of families and their children Our facility is furnished with the state of the art dental equipment carefully catered to provide the best patient experience possible under the careful supervision of specialist Dental Surgeons</p>
                <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
                  <li><a href="https://www.facebook.com/hopedentalcentre.org/"><span class="icon-facebook"></span></a></li>
                  <li><a href="https://www.instagram.com/hopedentalcentre/"><span class="icon-instagram"></span></a></li>
                </ul>

                <div class="founder d-flex align-items-center mt-5">
                  <div class="text pl-3">
                    <h3 class="mb-0"></h3>
                    <span class="position"></span>
                  </div>
                </div>
              </div>
            <!-- </vue-aos> -->
          </div>
        </div>
      </section>

      <section id="book" class="ftco-section ftco-no-pt ftco-no-pb">
        <div class="container-fluid px-md-0">
          <div class="row no-gutters">
            <div class="col-md-12 d-flex align-items-stretch">
              <div class="consultation consul w-100 px-4 px-md-5">
                <div class="text-center">
                  <h3 class="mb-4">
                    <strong>
                      Book Appointment
                    </strong>
                  </h3>
                </div>

                <el-form :model="appointmentForm" ref="appointmentForm" class="appointment-form">
                  <div class="row">
                    <div class="col-md-12 col-lg-6 col-xl-4">
                      <div class="form-group">
                        <el-form-item prop="name" required class="mb-3">
                          <el-input v-model="appointmentForm.name" placeholder="Patient Name"></el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-6 col-xl-4">
                      <div class="form-group">
                        <el-form-item prop="phone" required class="mb-3">
                          <div class="d-flex">
                            <div class="flex-fill">
                              <el-input value="+92" :disabled="true" prefix-icon="el-icon-mobile-phone"></el-input>
                            </div>

                            <el-input v-model="appointmentForm.phone" type="tel" maxlength="10" placeholder="3217654321"></el-input>
                          </div>
                        </el-form-item>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-6 col-xl-4">
                      <div class="form-group">
                        <div class="form-field">
                          <div class="select-wrap">
                            <el-form-item prop="doctor" required>
                              <el-select v-model="appointmentForm.doctor" placeholder="Select Doctor">
                                <el-option-group
                                  v-for="doctorCategory in doctorCategories"
                                  :key="doctorCategory.label"
                                  :label="doctorCategory.label">
                                  <el-option
                                    v-for="doctor in doctorCategory.doctors"
                                    :key="doctor.value"
                                    :label="doctor.label"
                                    :value="doctor.value">
                                  </el-option>
                                </el-option-group>
                              </el-select>
                            </el-form-item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-6 col-xl-4">
                      <div class="form-group">
                        <div class="input-wrap">
                          <el-form-item prop="date" required class="mb-3">
                            <el-date-picker type="date" placeholder="Pick a date" format="dd-MM-yyyy" v-model="appointmentForm.date"></el-date-picker>
                          </el-form-item>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-6 col-xl-4">
                      <div class="form-group">
                        <div class="input-wrap">
                          <el-form-item prop="time" required class="mb-3">
                            <vue-timepicker
                              format="hh:mm a"
                              :minute-interval="30"
                              :hour-range="['8a', '9a', '10a', '11a', '12p', ['1p', '9p']]"
                              auto-scroll
                              v-model="appointmentForm.time"
                              input-class="el-input__inner"
                              input-width="100%"
                            ></vue-timepicker>
                          </el-form-item>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-6 col-xl-4">
                      <div class="form-group">
                        <input type="button" value="Book Appointment" class="btn btn-secondary py-2 px-4 mt-0" style="background: #a8c337;border: 1px solid #a8c337;color: #fff;font-size: 1rem;" @click="saveAppointment">
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="ftco-section ftco-services" style="font-family: 'Poppins', Arial, sans-serif;background: #fff;font-size: 15px;line-height: 1.8;font-weight: 400;color: rgba(0, 0, 0, 0.6);">
        <div class="container">
          <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section">
              <span class="subheading">Services</span>
              <h2 class="mb-4">Our Clinic Services</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <span class="flaticon-drilling"></span>
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Fillings</h3>
                  <p>we use only the best and toughest swiss laser treated tooth coloured filling for life like & long lasting results.</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <span class="flaticon-tooth"></span>
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Childrens Hygiene</h3>
                  <p>we use behavior modification techniques to bring out the best patient in every child.</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <span class="flaticon-dental-floss"></span>
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Crown and Bridge</h3>
                  <p>we have a range of options and materials to suit your financial and aesthetic requirements.</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <span class="flaticon-shiny-tooth"></span>
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Hollywood Smile</h3>
                  <p>Looking for a full set of pearly whites? look no further, book your appointment today.</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <span class="flaticon-dentist-chair"></span>
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Surgical Extraction</h3>
                  <p>Expert consultants with generous experience will make sure your experience is pain-free and comfortable, we keep a check on our patients even after leaving the clinic.</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <span class="flaticon-tooth-1"></span>
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Scaling and Tooth Whitening</h3>
                  <p>Bring back those pearly whites and get rid of bleeding gums with our painless piezo scaler and polishing techniques.</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <span class="flaticon-tooth-with-braces"></span>
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Orthodontics</h3>
                  <p>Our specialist orthodontist with years of experience in fixing messed up teeth will have your teeth aligned in no time.</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <span class="flaticon-decayed-tooth"></span>
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Root Canel Treatment</h3>
                  <p>our specialist endondontists provide pain free treatment with reliable results using the latest technology available.</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <img src="img/denture-icon-17.jpg" alt="John" width="70%" height="70%">
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Dentures</h3>
                  <p>removable denture for partial coverage and full coverage.</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex services align-self-stretch p-4">
              <div class="media block-6 d-block text-center">
                <div class="icon d-flex justify-content-center align-items-center">
                  <img src="img/implants.png" alt="John" width="70%" height="70%">
                </div>
                <div class="media-body p-2 mt-3">
                  <h3 class="heading">Dental Implant</h3>
                  <p>our well trained and experienced surgeons use German implants to bring back life like smiles that look natural and last longer.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="ftco-section intro" style="background-image: url(img/bg_3.jpg);" data-stellar-background-ratio="0.5">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h3 class="mb-4">With Over 40 years of Experience in Dental Care, we promise to deliver the best.</h3>
              <p></p>
            </div>
          </div>
        </div>
      </section>

      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section">
              <span class="subheading">Doctors</span>
              <h2 class="mb-4">Our Qualified Doctors</h2>
              <p></p>
            </div>
          </div>  

          <div class="row">
            <div class="col-md-6 col-lg-4">
              <div class="staff">
                <div class="img-wrap d-flex align-items-stretch">
                  <div class="img " style="background-image: url(img/male-dentist.png);"></div>
                </div>
                <div class="text pt-3 text-center">
                  <h3>Dr. Abdul Hayee Rana</h3>
                  <span class="position mb-2">BDS, RDS
                    C Orthodontics
                    (Singapore)
                    C.T.I Dental Implants
                    (Singapore)</span>
                  <div class="faded">
                    <p>our most experienced consultant with years of experience, specializing in orthodontics, with crown, bridge and dental surgery as additional services.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="staff">
                <div class="img-wrap d-flex align-items-stretch">
                  <div class="img align-self-stretch" style="background-image: url(img/male-dentist.png);"></div>
                </div>
                <div class="text pt-3 text-center">
                  <h3>Dr. Arslan Rana</h3>
                  <span class="position mb-2">BDS, RDS
                    MJDF RCS/FGDP (UK)
                    Dip. AGD ( IADSR)
                    C. Implantology (PAID)
                    PGR Operative Dentistrty (IIDC)</span>
                  <div class="faded">
                    <p>our specialist root canal therapist and filling expert will bring back life to your worn out teeth, or replace it with dental implants to bring back your smile to its original beauty..</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="staff">
                <div class="img-wrap d-flex align-items-stretch">
                  <div class="img align-self-stretch" style="background-image: url(img/dentist-female.png);"></div>
                </div>
                <div class="text pt-3 text-center">
                  <h3>Dr. Nimrah Awan</h3>
                  <span class="position mb-2">BDS,RDS, M.Phil Oral pathology </span>
                  <div class="faded">
                    <p>Your everyday go to dental surgeon, specializing in teeth whitening and scaling for an Instagram worthy pearly smile.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="ftco-section testimony-section bg-light" style="font-family: 'Poppins', Arial, sans-serif;background: #fff;font-size: 15px;line-height: 1.8;font-weight: 400;color: rgba(0, 0, 0, 0.6);">
        <div class="container">
          <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section">
              <span class="subheading">Testimonials</span>
              <h2 class="mb-4">Our Patients Say About Us</h2>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-12">
              <carousel :autoplay="true" :loop="true" :perPage="3" :scrollPerPage="false">
                <slide>
                  <div class="item">
                    <div class="testimony-wrap d-flex">
                      <div class="user-img" style="background-image: url(img/testimonial-image.png)">
                      </div>
                      <div class="text pl-4 bg-light">
                        <span class="quote d-flex align-items-center justify-content-center">
                          <i class="icon-quote-left"></i>
                        </span>
                        <p>I can't recommend this dental practice highly enough, visited recently as a new patient requiring treatment. I found the reception staff to be most welcoming, approachable, and professional. The dentist and nurse work well together, you feel instantly at ease with them.</p>
                        <p class="name">Dawar</p>
                        <span class="position">Patient</span>
                      </div>
                    </div>
                  </div>
                </slide>

                <slide>
                  <div class="item">
                    <div class="testimony-wrap d-flex">
                      <div class="user-img" style="background-image: url(img/testimonial-female.png)">
                      </div>
                      <div class="text pl-4 bg-light">
                        <span class="quote d-flex align-items-center justify-content-center">
                          <i class="icon-quote-left"></i>
                        </span>
                        <p>Very happy with this practice. Our family of five have been with this practice for 12 years. Have only praise for this team. All three children were referred for orthodontic work and I have had a few issues which were always dealt with efficiently. I flew in from abroad having suffered from a painful infection needing urgent treatment and saw the dentist within hours of landing. Highly recommended..</p>
                        <p class="name">Sara</p>
                        <span class="position">Patient</span>
                      </div>
                    </div>
                  </div>
                </slide>

                <slide>
                  <div class="item">
                    <div class="testimony-wrap d-flex">
                      <div class="user-img" style="background-image: url(img/testimonial-female.png)">
                      </div>
                      <div class="text pl-4 bg-light">
                        <span class="quote d-flex align-items-center justify-content-center">
                          <i class="icon-quote-left"></i>
                        </span>
                        <p>I have a fair few disorders, Tourette’s and OCD being the main ones and they totally appreciate your difficulties and put you at ease every step of the way. They're also amazing with kids too. I seriously would not recommend anywhere else but here, fantastic practice and this comes from someone who is petrified of dentists and haven't been in 12yrs..</p>
                        <p class="name">Nausheen</p>
                        <span class="position">Patient</span>
                      </div>
                    </div>
                  </div>
                </slide>

                <slide>
                  <div class="item">
                    <div class="testimony-wrap d-flex">
                      <div class="user-img" style="background-image: url(img/testimonial-image.png)">
                      </div>
                      <div class="text pl-4 bg-light">
                        <span class="quote d-flex align-items-center justify-content-center">
                          <i class="icon-quote-left"></i>
                        </span>
                        <p>After 55 years of using dentists, finally one that just works. Appointment system is brilliant, always very accommodating with times. A reminder SMS is sent the working day beforehand. I also had a call confirming appointment. The dentist themselves was in my view excellent..</p>
                        <p class="name">Mr.Arshad</p>
                        <span class="position">Patient</span>
                      </div>
                    </div>
                  </div>
                </slide>

                <slide>
                  <div class="item">
                    <div class="testimony-wrap d-flex">
                      <div class="user-img" style="background-image: url(img/testimonial-image.png)">
                      </div>
                      <div class="text pl-4 bg-light">
                        <span class="quote d-flex align-items-center justify-content-center">
                          <i class="icon-quote-left"></i>
                        </span>
                        <p>My dentist is possibly the best dentist I have ever seen both from ability and their fantastic people skills. I was made to feel comfortable in what was not simple procedures.A personal thank you from myself for such exceptional visits and I am extremely happy with the work done! .</p>
                        <p class="name">Rubaid</p>
                        <span class="position">Patient</span>
                      </div>
                    </div>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Parallax from 'vue-parallaxy'
import { Carousel, Slide } from 'vue-carousel'
import VueAos from 'vue-aos'
import moment from 'moment'
import { getDoctors, createAppointment } from '@/services/api'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'index',

  bodyClass: 'landing-page',

  components: {
    Parallax,
    VueAos,
    Carousel,
    Slide,
    VueTimepicker,
  },

  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      },
      appointmentForm: {
        doctor: '',
        date: '',
        time: '',
        name: '',
        phone: '',
        gender: '',
        dob: '',
        status: 'pending',
      },
      doctorCategories: [{
        label: 'Dentist',
        doctors: [],
      }],
    }
  },

  created() {
    getDoctors()
      .then(response =>
        response.data.forEach(doctor => this.doctorCategories[0].doctors.push({ label: doctor.attributes.name, value: doctor.id }))
      )
  },

  methods: {
    saveAppointment() {
      this.$refs['appointmentForm'].validate(valid => {
        if (!valid) return false

        let timeWithAmPm = this.appointmentForm.time.split(' ')
        let hoursAndMinutes = timeWithAmPm[0].split(':')
        let hours = parseInt(hoursAndMinutes[0])

        if (timeWithAmPm[1] == 'pm') hours += 12

        let from = moment(this.appointmentForm.date).set({ hours: hours, minutes: hoursAndMinutes[1] }).utc()

        let params = {
          appointment: {
            appointment_on: moment(this.appointmentForm.date).format('DD-MM-YYYY'),
            from: from,
            to: moment(from).add(30, 'minutes').utc(),
            status: 'request',
            created_by: 'patient',

            patient_attributes: {
              name: this.appointmentForm.name,
              phone: this.appointmentForm.phone,
              gender: this.appointmentForm.gender,
              status: this.appointmentForm.status,
              dob: this.appointmentForm.dob,
              doctor_id: this.appointmentForm.doctor,
            }
          }
        }

        createAppointment(params)
          .then(response => {
            this.$message({
              showClose: true,
              message: 'The appointment was saved successfully.',
              type: 'success'
            });

            this.$refs['appointmentForm'].resetFields();
            this.clearAppointmentForm()
          })
      });
    },

    clearAppointmentForm() {
      this.appointmentForm = {
        doctor: '',
        date: '',
        time: '',
        name: '',
        phone: '',
        gender: '',
        dob: '',
        status: 'pending',
      }
    },
  },
};
</script>

<style>
  .home .el-col {
    border-radius: 4px;
  }
  .home .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .home .subheading {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
  }
  .home .appointment-form .el-form-item__error {
    color: rgb(168, 195, 55);
    font-weight: 400;
  }
  .home .appointment-form .el-input__inner {
    font-family: Arial, sans-serif;
    display: block !important;
    width: 100% !important;
    border: transparent !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
    height: 40px !important;
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    color: rgba(255, 255, 255, 0.9) !important;
    font-size: 13.33px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    border-radius: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .home .appointment-form .el-input__inner::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-size: 13.33px !important;
  }
  .home .appointment-form .el-input__inner::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-size: 13.33px !important;
  }
  .home .appointment-form .el-input__inner:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-size: 13.33px !important;
  }
  .home .appointment-form .el-input__inner:-moz-placeholder {
    /* Firefox 18- */
    color: #fff !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-size: 13.33px !important;
  }
  .home .appointment-form .form-control:focus, .appointment-form .form-control:active {
    border-color: #fff;
  }
</style>
