import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import NowUiKit from './plugins/now-ui-kit';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Popover from 'vue-js-popover';

Vue.config.productionTip = false;

Vue.use(NowUiKit);
Vue.use(ElementUI);
Vue.use(Popover);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
