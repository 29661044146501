<template>
  <div v-loading="isLoading" class="mt-4 mx-4">
    <div v-if="currentPatient != null" class="row">
      <div class="col-xl-3">
        <el-card class="box-card" header="Patient Profile">
          <div class="d-flex justify-content-center align-items-center">
            <span class="patient-avatar d-flex justify-content-center align-items-center">
              <i class="el-icon-user-solid text-primary patient-avatar-icon"></i>
            </span>
          </div>

          <div class="d-flex align-items-center align-items-stretch text-center flex-column">
            <div class="title">{{ currentPatient.attributes.name }}</div>
            <div v-if="currentPatient.attributes.x_ray_number" class="small-title mt-2">X-Ray: {{ currentPatient.attributes.x_ray_number }}</div>
            <div v-if="currentPatient.attributes.gender && currentPatient.attributes.dob" class="small-title mt-2 capitalize">{{ currentPatient.attributes.gender }} | <span v-if="currentPatient.attributes.dob">{{ patientAge }}</span> </div>
            <div class="small-title mt-2">+92 {{ currentPatient.attributes.phone }}</div>
            <div class="small-title mt-2">{{ currentPatient.attributes.medical_history.join(', ') }}</div>
            <el-button icon="el-icon-message" type="primary" class="mt-3" size="small" plain @click="editPatient(currentPatient.attributes)">Edit Patient</el-button>
            <el-button icon="el-icon-plus" type="primary" class="mt-3 ml-0" size="small" plain @click="toggleAppointmentForm()">Add Appointment</el-button>
          </div>
        </el-card>

        <el-card class="box-card mt-4">
          <div slot="header" class="d-flex justify-content-between align-items-center">
            <span>Prescription</span>
            <el-button icon="el-icon-printer" type="primary" size="small" plain @click="onPrintPrescription()">Print</el-button>
          </div>

          <el-table
            :data="prescription"
            height="200"
          >
            <el-table-column
              prop="medication"
              label="Medication"
            ></el-table-column>

            <el-table-column
              prop="duration"
              label="Duration (Days)"
            ></el-table-column>

            <el-table-column
              prop="frequency"
              label="Per Day"
            ></el-table-column>
          </el-table>
        </el-card>

        <el-card v-if="currentPatient != null" class="box-card mt-4" header="Treatment History" style="background-color: #FBFBFB !important;">
          <el-table
            v-if="treatmentHistory.length > 0"
            :data="treatmentHistory"
            height="400"
            :show-header="false"
            row-class-name="bg-none"
          >
            <el-table-column prop="message"></el-table-column>

            <el-table-column
              fixed="right"
              label="Operations"
              width="120">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="showTreatmentDetail(scope.$index, scope.row)"
                >Detail</el-button>
              </template>
            </el-table-column>
          </el-table>

          <p v-else class="mb-0">No medical history exist.</p>

          <el-drawer
            v-if="previewTreatment != null"
            :title="`Appointment on ${appointmentOn}`"
            :visible.sync="showTreatmentDrawer"
            :with-header="false"
            size="50%"
          >
            <div class="el-card__header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Appointment On: {{ appointmentOn }}</h5>
                <el-button icon="el-icon-printer" type="primary" size="small" plain @click="onPrintPreviousPrescription(previewTreatment)">Print</el-button>

                <div @click="showTreatmentDrawer = false" class="el-button el-button--danger is-circle el-button--mini">
                  <i class="el-icon-close"></i>
                </div>
              </div>
            </div>

            <div class="d-flex overflow-auto flex-column p-4" style="max-height: 100vh;">
              <div class="mb-4">
                <h6>Foot Note</h6>

                <p>{{ previewTreatment.attributes.description == null || previewTreatment.attributes.description == "" ? 'n/a' : previewTreatment.attributes.description }}</p>
              </div>

              <div class="mb-4">
                <h6>Treatment</h6>

                <el-table v-if="previewTreatment.attributes.applied_procedures.data.length > 0" :data="appliedProcedures">
                  <el-table-column prop="name" label="Name"></el-table-column>
                  <el-table-column prop="toothId" label="Tooth Id"></el-table-column>
                </el-table>
                <p v-else>n/a</p>
              </div>

              <div v-if="appliedDiagnoses.length > 0" class="mb-4">
                <h6>Diagnoses</h6>

                <el-table :data="appliedDiagnoses">
                  <el-table-column prop="name" label="Name"></el-table-column>
                  <el-table-column prop="toothId" label="Tooth Id"></el-table-column>
                </el-table>
              </div>

              <div class="my-4">
                <h6>Medications</h6>

                <el-table :data="appliedMedications">
                  <el-table-column prop="medication_name" label="Name"></el-table-column>
                  <el-table-column prop="duration" label="Duration(Days)"></el-table-column>
                  <el-table-column prop="frequency" label="Frequency"></el-table-column>
                </el-table>
              </div>
            </div>
          </el-drawer>
        </el-card>

        <el-card class="box-card mt-4" header="Appointment History" style="background-color: #FBFBFB !important;">
          <el-table
            v-if="appointments.length > 0"
            :data="appointments"
            :show-header="false"
            row-class-name="bg-none"
          >
            <el-table-column prop="attributes.comments"></el-table-column>
            <el-table-column prop="attributes.appointment_on"></el-table-column>
          </el-table>

          <p v-else class="mb-0">No appointment history exist!</p>
        </el-card>
      </div>

      <div class="col-xl-9 px-3">
        <el-card class="box-card">
          <div class="d-flex flex-column align-items-center">
            <div class="row w-100" style="min-height: 200px;">
              <div class="col-md-12">
                <el-tabs type="card" @tab-click="onPatientTypeClick">
                  <el-tab-pane label="Adult">
                    <div class="justify-content-center">
                      <el-tabs type="card"  @tab-click="onTeethChartFormTabClick">
                        <el-tab-pane label="Diagnosis">
                          <div class="justify-content-center">
                            <tooth-chart chart-type="Adult" chart-category="Diagnosis" />
                          </div>
                        </el-tab-pane>

                        <el-tab-pane label="Treatment">
                          <div class="justify-content-center">
                            <tooth-chart chart-type="Adult" chart-category="Treatment" />
                          </div>
                        </el-tab-pane>

                        <el-tab-pane label="Medication">
                          <div class="justify-content-center">
                            <medications />
                          </div>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="Child">
                    <div class="justify-content-center">
                      <el-tabs type="card"  @tab-click="onTeethChartFormTabClick">
                        <el-tab-pane label="Diagnosis">
                          <div class="justify-content-center">
                            <tooth-chart chart-type="Child" chart-category="Diagnosis" />
                          </div>
                        </el-tab-pane>

                        <el-tab-pane label="Treatment">
                          <div class="justify-content-center">
                            <tooth-chart chart-type="Child" chart-category="Treatment" />
                          </div>
                        </el-tab-pane>

                        <el-tab-pane label="Medication">
                          <div class="justify-content-center">
                            <medications />
                          </div>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </el-card>

        <div class="col-cl-12 mt-4">
          <el-card class="box-card">
            <template #header>
              <div class="d-flex justify-content-between">
                <span>Prescription Management</span>
                <el-button type="primary" size="small" @click="saveTreatment()">Save Prescription</el-button>
              </div>
            </template>

            <div class="treatment-form-container">
              <el-form :model="treatmentForm">
                <div class="row" style="min-height: 200px;">
                  <div class="col-md-12">
                    <el-tabs type="card">
                      <el-tab-pane label="Diagnosis">
                        <div class="row">
                          <div
                            v-for="(diagnosis, index) in treatmentForm.diagnoses"
                            :key="diagnosis.key"
                            class="d-flex col-6 align-items-center"
                          >
                            <el-form-item label="Tooth Id" class="mr-4" size="small">
                              <el-input
                                :value="diagnosis.toothId"
                                :disabled="true"
                                size="small"
                              ></el-input>
                            </el-form-item>

                            <el-form-item label="Diagnosis" class="mr-4" size="small">
                              <el-select v-model="diagnosis.id" class="w-100" size="small" clearable filterable>
                                <el-option
                                  v-for="getDiagnosis in getDiagnoses"
                                  :key="getDiagnosis.id"
                                  :label="getDiagnosis.name"
                                  :value="getDiagnosis.id"
                                />
                              </el-select>
                            </el-form-item>

                            <el-button class="mt-3" type="danger" icon="el-icon-close" size="mini" round @click.prevent="removeDiagnosis(index)" />
                          </div>
                        </div>
                      </el-tab-pane>

                      <el-tab-pane label="Treatment">
                        <div class="row">
                          <div
                            v-for="(procedure, index) in treatmentForm.procedures"
                            :key="procedure.key"
                            class="d-flex col-6 align-items-center"
                          >
                            <el-form-item label="Tooth Id" class="mr-4" size="small">
                              <el-input
                                :value="procedure.toothId"
                                :disabled="true"
                                size="small"
                              ></el-input>
                            </el-form-item>

                            <el-form-item label="Treatment" class="mr-4" size="small">
                              <el-select v-model="procedure.id" size="small" class="w-100" clearable filterable>
                                <el-option
                                  v-for="(getProcedure, index) in getProcedures"
                                  :key="`procedure_${getProcedure.id}`"
                                  :label="getProcedure.name"
                                  :value="getProcedure.id"
                                />
                              </el-select>
                            </el-form-item>

                            <el-button class="mt-3" type="danger" icon="el-icon-close" size="mini" round @click.prevent="removeProcedure(index)" />
                          </div>
                        </div>
                      </el-tab-pane>

                      <el-tab-pane label="Medication">
                        <div
                          v-for="medication in treatmentForm.medications"
                          :key="medication.key"
                          class="d-flex align-items-center"
                        >
                          <el-form-item label="Name" class="mr-4" size="small">
                            <el-select v-model="medication.id" size="small" class="w-100" clearable filterable autocomplete>
                              <el-option
                                v-for="(medication, index) in getMedications"
                                :key="`medication_${medication.id}`"
                                :label="medication.name"
                                :value="medication.id"
                              />
                            </el-select>
                          </el-form-item>

                          <el-form-item label="No. of Days" class="mr-4" size="small">
                            <el-input-number v-model="medication.duration" size="small" controls-position="right" :min="1" />
                          </el-form-item>

                          <el-form-item label="Per day" class="mr-4" size="small">
                            <el-select v-model="medication.frequency" size="small" class="w-100">
                              <el-option
                                v-for="(frequency, index) in frequencies"
                                :key="`frequency_${index}`"
                                :label="frequency"
                                :value="frequency"
                              />
                            </el-select>
                          </el-form-item>

                          <el-button class="mt-3" type="danger" icon="el-icon-close" size="mini" round @click.prevent="removeMedication(medication)" />
                        </div>

                        <el-form-item label="Foot Note" class="w-100" size="small">
                          <el-input v-model="treatmentForm.description" type="textarea" :rows="2" clearable />
                        </el-form-item>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                </div>
              </el-form>
            </div>

            <div class="d-flex justify-content-end">
              <el-button type="primary" size="small" @click="saveTreatment()">Save Prescription</el-button>
            </div>
          </el-card>
        </div>
      </div>
    </div>

    <Prescription />

    <appointment-form ref="appointmentFormModal" />
  </div>
</template>

<script>
import ToothChart from '@/components/tooth-chart'
import Medications from '@/components/medications.vue'
import moment from 'moment'
import { getPatient, getAppointments, getMedications, getProcedures, createTreatment } from '@/services/api'
import EventBus from '@/services/event-bus'
import { mapGetters, mapActions } from 'vuex'
import AppointmentForm from './appointments/create'
import Prescription from '@/components/Prescription'

export default {
  components: {
    ToothChart,
    Medications,
    Prescription,
    AppointmentForm,
  },

  data() {
    return {
      isLoading: false,
      patientType: 'Adult',
      currentPatient: null,
      previewTreatment: null,
      currentTeethTab: 0,
      teethChartCategory: 'Diagnosis',
      showTreatmentDrawer: true,
      visibleTreatmentForm: false,
      appointments: [],
      treatmentForm: {
        advice: '',
        toothId: '',
        description: '',
        appointmentId: '',
        diagnoses: [],
        procedures: [],
        medications: [],
      },
      frequencies: ['1 - 0 - 0', '1 - 0 - 1', '1 - 1 - 1'],
    }
  },

  created() {
    this.loadProcedures()
    this.loadMedications()
    this.loadDiagnoses()

    this.treatmentForm.appointmentId = this.$route.query.appointment_id
    this.isLoading = true
    getPatient(this.$route.params.id)
      .then(response => {
        this.currentPatient = response.data
        this.isLoading = false
      })

    getAppointments({ patient_appointments: true, patient_id: this.$route.params.id })
      .then(response => {
        this.appointments = response.data
      })
  },

  mounted() {
    EventBus.$on('onDiagnosesApplied', (diagnoses) => {
      this.addDiagnosesNew(diagnoses);
    })

    EventBus.$on('onProceduresApplied', (procedures) => {
      this.addProceduresNew(procedures);
    })

    EventBus.$on('onMedicationsApplied', (treatment_form) => {
      this.addMedicationsNew(treatment_form.medications);
      this.treatmentForm.description = treatment_form.description || "";
    })
  },

  computed: {
    ...mapGetters(['getProcedures', 'getMedications', 'getDiagnoses']),
    patientAge() {
      let patientAge = moment().diff(this.currentPatient.attributes.dob, 'years', false)
      if (patientAge) {
        return `${patientAge} years`
      } else {
        return '0 years'
      }
    },
    treatmentHistory() {
      let treatments = []

      this.currentPatient.attributes.treatments.data.forEach(treatment =>
        treatments.push({ id: treatment.id, message: `Appointment with Dr. ${treatment.attributes.doctor_name} on ${moment(treatment.attributes.appointment_on).format('DD-MM-yyyy')} at ${moment(treatment.attributes.from).format('LT')}` })
      )

      return treatments
    },
    appliedMedications() {
      let appliedMedications = []

      this.previewTreatment.attributes.applied_medications.data.forEach(appliedMedication =>
        appliedMedications.push({
          medication_name: appliedMedication.attributes.medication_name,
          toothId: appliedMedication.attributes.tooth_id,
          duration: appliedMedication.attributes.duration,
          frequency: appliedMedication.attributes.frequency,
        })
      )

      return appliedMedications
    },
    appliedProcedures() {
      let appliedProcedures = []

      this.previewTreatment.attributes.applied_procedures.data.forEach(appliedProcedure => {
        appliedProcedures.push({
          name: appliedProcedure.attributes.procedure_name,
          toothId: appliedProcedure.attributes.tooth_id || '-',
        })
      })

      return appliedProcedures
    },
    appliedDiagnoses() {
      let appliedDiagnoses = []

      if (this.previewTreatment.attributes.applied_diagnoses && this.previewTreatment.attributes.applied_diagnoses.length > 0) {
        this.previewTreatment.attributes.applied_diagnoses.forEach(appliedDiagnosis => {
          appliedDiagnoses.push({
            id: appliedDiagnosis.id,
            name: appliedDiagnosis.name,
            toothId: appliedDiagnosis.tooth_id || '-',
          })
        })
      }

      return appliedDiagnoses
    },
    appointmentOn() {
      return moment(this.previewTreatment.attributes.appointment_on).format('DD-MM-yyyy')
    },
    prescriptionDiagnoses() {
      let diagnoses = []
      this.treatmentForm.diagnoses.forEach(treatmentDiagnosis => {
        let index = this.getDiagnoses.findIndex(diagnosis => diagnosis.id == treatmentDiagnosis.id)
        if (index > -1) treatmentDiagnosis.name = this.getDiagnoses[index].name
        diagnoses.push({ name: treatmentDiagnosis.name, toothId: treatmentDiagnosis.toothId })
      })
      return diagnoses
    },
    uniquePrescriptionDiagnoses() {
      let diagnoses = prescriptionDiagnoses;
      let diagnosesKeys = [];
      let diagnosesGroup = [];

      if(diagnoses.length > 0) {
        diagnoses.forEach(diagnosis => {if(!diagnosesKeys.includes(diagnosis.name)) { diagnosesKeys.push(diagnosis.name) }});

        diagnosesKeys.forEach(value => {
          let sameObj = diagnoses.filter(diagnosis => { return diagnosis.name == value })

          diagnosesGroup.push({[value]: sameObj});

        })

      }

      return diagnosesGroup;
    },
    prescription() {
      let medications = []
      this.treatmentForm.medications.forEach(treatmentMedication => {
        let index = this.getMedications.findIndex(medication => medication.id == treatmentMedication.id)
        if (index > -1) treatmentMedication.name = this.getMedications[index].name
        medications.push({ medication: treatmentMedication.name, duration: treatmentMedication.duration, frequency: treatmentMedication.frequency })
      })
      return medications
    },
    calculateCurrentPatientDob() {
      return this.currentPatient.attributes.dob != null ? `${moment().diff(this.currentPatient.attributes.dob, 'years')} years` : '-'
    },
  },

  methods: {
    ...mapActions(['loadProcedures', 'loadMedications', 'loadDiagnoses']),
    editPatient(patient) {
      EventBus.$emit('onAddPatientClick', true, 'edit', patient)
    },
    showTreatmentDetail(index, row) {
      this.showTreatmentDrawer = true
      this.previewTreatment = this.currentPatient.attributes.treatments.data.find(treatment => treatment.id == row.id)
    },
    onPrintPreviousPrescription(selectedTreatForm) {
      let clickedTreatmentForm = {
        advice: '',
        toothId: '',
        description: '',
        appointmentId: '',
        diagnoses: [],
        procedures: [],
        medications: [],
      }

      const { advice, applied_diagnoses, applied_medications,
        applied_procedures, description, tooth_id } = selectedTreatForm.attributes;

      clickedTreatmentForm.advice = advice;
      clickedTreatmentForm.toothId = tooth_id;
      clickedTreatmentForm.description = description;
      clickedTreatmentForm.appointmentId = selectedTreatForm.id;
      clickedTreatmentForm.diagnoses = applied_diagnoses;
      clickedTreatmentForm.procedures = applied_procedures.data;
      clickedTreatmentForm.medications = applied_medications.data;

      this.$emit('treatmentFormUpdate', clickedTreatmentForm);
    },
    addDiagnosesNew(diagnoses) {
      let toothType = "";
      if (diagnoses.length > 0) {
        toothType = diagnoses[0].toothType || "";
      }
      this.treatmentForm.diagnoses = this.treatmentForm.diagnoses.filter(item => item.toothType != toothType);

      diagnoses.forEach(diagnosis => {
        diagnosis.teethIds.forEach(toothId => {
          this.treatmentForm.diagnoses.push({
            id: diagnosis.id,
            toothId,
            toothType: diagnosis.toothType,
            key: `${toothId}${diagnosis.key}`
          })
        })
      });
      this.visibleTreatmentForm = true;
    },
    addProceduresNew(procedures) {
      let toothType = "";
      if (procedures.length > 0) {
        toothType = procedures[0].toothType || "";
      }
      this.treatmentForm.procedures = this.treatmentForm.procedures.filter(item => item.toothType != toothType);

      procedures.forEach(procedure => {
        procedure.teethIds.forEach(toothId => {
          this.treatmentForm.procedures.push({
            id: procedure.id,
            toothId,
            toothType: procedure.toothType,
            key: `${toothId}${procedure.key}`
          })
        })
      });
      this.visibleTreatmentForm = true;
    },
    addMedicationsNew(medications) {
      this.treatmentForm.medications = [];
      medications.forEach(medication => {
        const {id, duration, frequency, key} = medication;
        this.treatmentForm.medications.push({ id, duration, frequency, key })
      });
      this.visibleTreatmentForm = true;
    },
    removeMedication(medication, medicationIndex) {
      let index = this.treatmentForm.medications.indexOf(medication)
      if (index !== -1) this.treatmentForm.medications.splice(index, 1)
    },
    removeProcedure(index) {
      if (index !== -1) this.treatmentForm.procedures.splice(index, 1)
    },
    removeDiagnosis(index) {
      if (index !== -1) this.treatmentForm.diagnoses.splice(index, 1)
    },
    saveTreatment() {
      let params = {
        patient_id: this.currentPatient?.attributes?.id,
        treatment: {
          advice: this.treatmentForm.advice,
          description: this.treatmentForm.description,
          appointment_id: this.treatmentForm.appointmentId,
          applied_diagnoses_attributes: this.treatmentForm.diagnoses.map(diagnosis => { return { diagnosis_id: diagnosis.id, tooth_id: diagnosis.toothId } }),
          applied_procedures_attributes: this.treatmentForm.procedures.map(procedure => { return { procedure_id: procedure.id, tooth_id: procedure.toothId } }),
          applied_medications_attributes: this.treatmentForm.medications.map(medication => { return { medication_id: medication.id, duration: medication.duration, frequency: medication.frequency } })
        }
      }

      createTreatment(params)
        .then(response => {
          this.currentPatient.attributes.treatments.data.push(response.data)
          this.$message({ showClose: true, message: 'Treatment was saved successfully.', type: 'success' });
          this.visibleTreatmentForm = false
        })
    },
    onPrintPrescription() {
      this.$emit('treatmentFormUpdate', this.treatmentForm);
    },
    discardChanges() {
      this.visibleTreatmentForm = false
      this.currentToothId = ''
      this.treatmentForm.advice = ''
      this.treatmentForm.toothId = ''
      this.treatmentForm.description = ''
      this.treatmentForm.diagnoses = []
      this.treatmentForm.procedures = []
      this.treatmentForm.medications = []
    },
    onTeethChartFormTabClick(tab) {
      this.currentTeethTab = tab.index;
    },
    onPatientTypeClick(tab) {
      this.patientType = event.target.textContent;
    },
    toggleAppointmentForm() {
      this.$refs.appointmentFormModal.show(this.currentPatient);
    },
  },
};
</script>

<style lang="scss">
.rotate-90 {
  transform: rotateZ(90deg);
}
.bg-none {
  background-color: inherit !important;
}
</style>
