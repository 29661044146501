<template>
  <div class="mt-4 mx-4">
    <popover name="patientPreview" :width="300">
      <el-card  v-if="previewAppointment != null" shadow="never" :body-style="{ padding: '10px' }">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div>
              <p class="patient-name mb-0">
                <b>{{ previewAppointment.attributes.patient.data.attributes.name }}</b>
              </p>

              <p class="patient-contact mb-0"><b>+92{{ previewAppointment.attributes.patient.data.attributes.phone }}</b></p>
              <p v-if="previewAppointment.attributes.patient.data.attributes.doctor.data" class="patient-contact mb-0">
                <b>({{ previewAppointment.attributes.patient.data.attributes.doctor.data.attributes.name }})</b>
              </p>
            </div>
            <div>
              <p class="patient-name capitalize text-right mb-0">{{ previewAppointment.attributes.status }}</p>
            </div>
          </div>

          <div class="d-flex justify-content-between">
            <p class="patient-contact text-right mb-0">Start: <b>{{ convertUtcToLocal(previewAppointment.attributes.from) }}</b></p>
            <p class="patient-contact text-right mb-0">End: <b>{{ convertUtcToLocal(previewAppointment.attributes.to) }}</b></p>
          </div>

          <div v-if="previewAppointment.attributes.comments">
            <el-divider class="my-2" />
            <div class="d-flex justify-content-between">
              <p class="patient-contact mb-0">Comment: <b>{{ previewAppointment.attributes.comments }}</b></p>
            </div>
          </div>

          <el-divider class="my-2" />
          <div class="d-flex my-2">
            <el-select v-model="engageInSurgery" placeholder="Select" size="small">
              <el-option label="Surgery 1" :value="0"></el-option>
              <el-option label="Surgery 2" :value="1"></el-option>
              <el-option label="Surgery 3" :value="2"></el-option>
              <el-option label="Surgery 4" :value="3"></el-option>
              <el-option label="Next" :value="4"></el-option>
            </el-select>

            <el-button type="primary" size="small" plain @click="updateAppointmentStatus('engaged')">Engage</el-button>
          </div>

          <div>
            <el-divider class="my-2" />

            <el-row>
              <el-tooltip content="Cancel Appointment" placement="bottom">
                <el-button type="danger" icon="el-icon-close" circle size="small" @click="updateAppointmentStatus('cancelled')"></el-button>
              </el-tooltip>

              <el-tooltip content="Send Message" placement="bottom">
                <el-button type="warning" icon="el-icon-message" circle size="small"></el-button>
              </el-tooltip>

              <el-tooltip content="Patient Profile" placement="bottom">
                <router-link :to="{ name: 'patients-show', params: { id: previewAppointment.attributes.patient.data.id }, query: { appointment_id: previewAppointment.id } }" class="el-button el-button--primary el-button--small is-circle">
                  <i class="el-icon-user"></i>
                </router-link>
              </el-tooltip>

              <el-tooltip content="Patient Arrived" placement="bottom">
                <el-button type="success" icon="el-icon-view" size="small" circle @click="updateAppointmentStatus('arrived')"></el-button>
              </el-tooltip>

              <el-tooltip content="Appointment Follow Up" placement="bottom">
                <el-button type="info" icon="el-icon-s-release" size="small" circle @click="updateAppointmentStatus('follow_up')"></el-button>
              </el-tooltip>
            </el-row>
          </div>
        </div>
      </el-card>
    </popover>

    <div class="row">
      <div class="col-md-12">
        <card class="card-nav-tabs text-center" header-classes="card-header-warning" style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);">
          <div class="d-flex justify-content-end mb-2">
            <el-button type="primary" @click="sendReminders()">Send Reminders</el-button>
          </div>
          <div class="row">
            <div class="col-md-4 form-inline">
              <label class="mr-2">Doctor: </label>
              <el-select v-model="doctorFilter" @change="doctorAppointments()" placeholder="Select Doctor" size="small">
                <el-option-group
                  v-for="doctorCategory in doctorsList"
                  :key="doctorCategory.label"
                  :label="doctorCategory.label">
                  <el-option
                    v-for="doctor in doctorCategory.doctors"
                    :key="doctor.value"
                    :label="doctor.label"
                    :value="doctor.value">
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
            <div class="col-md-8 form-inline">
              <label class="mr-2">Date Range: </label>
              <el-date-picker
                v-model="dateRangeFilter"
                @change="doctorAppointments()"
                type="daterange"
                range-separator="-"
                start-placeholder="Start date"
                end-placeholder="End date"
                format="dd-MM-yyyy"
                :size="dateRangesize"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 d-flex align-items-center">
              <ul class="colors-container my-4">
                <li class="d-flex align-items-center mr-2">
                  <span class="status-color booked"></span> Booked
                </li>
                <li class="d-flex align-items-center mx-2">
                  <span class="status-color patient-arrived"></span> Patient Arrived
                </li>
                <li class="d-flex align-items-center mx-2">
                  <span class="status-color cancelled"></span> Cancelled
                </li>
              </ul>
            </div>
          </div>

          <div class="row full-calendar-row">
            <div v-loading="isAppointmentsLoading" class="col-md-12">
              <full-calendar v-if="viewType == 'calendar'" ref="fullCalendar" :options='calendarOptions'>
                <template #eventContent="{ timeText, event }">
                  <p v-popover.bottom="{ name: 'patientPreview' }" class="font-15 font-weight-500 w-100 cursor-pointer mb-0 text-white" @click="setPreviewAppointment(event.id)">
                    <span>{{ event.extendedProps.patientName }}</span><br/>
                    <span>{{ event.extendedProps.patientPhone }}</span><br/>
                    <span v-if="event.extendedProps.comment">{{ event.extendedProps.comment }}<br/></span>
                    <span v-else>No comments found<br/></span>
                    <span class="font-13 font-normal">{{ `${event.extendedProps.doctorName} - ${timeText}` }}</span>
                  </p>
                </template>
              </full-calendar>

              <div v-else>
                <div v-if="doctorsWithAppointments.length > 0" class="el-table el-table--fit el-table--border el-table--enable-row-hover el-table--enable-row-transition">
                  <table class="w-100">
                    <thead>
                      <tr>
                        <th colspan="1" rowspan="1" class="el-table_4_column_10 is-leaf"><div class="cell">Time</div></th>
                        <th
                          v-for="doctor in doctorsWithAppointments"
                          :key="doctor.id"
                          colspan="1" rowspan="1" :class="`el-table_4_column_${doctor.id} is-leaf`"
                        >
                          <div class="cell">{{ doctor.attributes.name }}</div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(hour, index) in hours"  :key="index" class="el-table__row">
                        <td><div class="cell">{{ hour }}</div></td>
                        <td
                          v-for="(doctor, doctorIndex) in getDoctorsWithAppointments"
                          :key="doctor.id"
                          :set="appointment = getAppointmentForDoctor(doctorIndex, hour)"
                          style="padding: 0px !important"
                        >
                          <div
                            v-if="appointment != null"
                            class="cell bg-primary text-white cursor-pointer"
                            style="height: 30px;border-radius: 2px;display: flex;align-items: center;"
                          >{{ appointment.attributes.patient.data.attributes.name }}</div>
                          <div v-else class="cell"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p v-else>No, appointments for the selected date.</p>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <el-card>
          <div class="row">
            <div class="col-md-12" style="height: 760px; overflow-y: auto;">
              <h2 style="font-size: 1.7em;">
                Follow Up Appointments
              </h2>

              <div
                v-for="(appointment) in followUpAppointments"
                :key="appointment.key"
                class="card"
              >
                <div class="card-body px-1 py-0">
                  <div class="lv-card-container pt-2 pl-2">
                    <p class="text-primary font-14 font-weight-500">
                      Name: {{ appointment.attributes.patient.data.attributes.name }}<br/>
                      Phone: {{ `+92 ${appointment.attributes.patient.data.attributes.phone}` }}<br/>
                      Dr. Name: {{ appointment.attributes.patient.data.attributes.doctor.data.attributes.name }}<br/>
                      Appointment On: {{ appointment.attributes.appointment_on }}<br/>
                      Time: {{ `${appointmentStartTime(appointment.attributes.from)} To ${appointmentStartTime(appointment.attributes.to)}` }}
                    </p>
                    <div class="d-flex justify-content-end mb-2 px-2">
                      <el-button type="danger" @click.prevent="deleteFollowUpAppointment(appointment.id)">
                        Delete <el-icon class="el-icon-close"></el-icon>
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </el-card>
      </div>
      <div class="col-md-6">
        <el-card>
          <el-button class="pull-right" type="primary" size="small" plain @click="onPrintAppointments()">Print Appointment</el-button>
          <div class="clearfix"></div>

          <div class="row">
            <div class="col-md-12">
              <h2 style="font-size: 1.7em; margin-top: .9em;">
                Live View
                <el-link class="pull-right" icon="el-icon-full-screen" style="font-size: 18px;" @click="openFullScreen()"></el-link>
              </h2>

              <div class="card apt-lv-card">
                <div class="card-body px-1">
                  <div class="lv-card-container pt-2 pl-2">
                    <h6 class="d-flex align-items-center text-primary">
                      <i class="el-icon-user mr-2" />
                      {{ getEngagedAppointments[0] || '...' }}
                    </h6>

                    <div class="lv-surgery">
                      <h6>Surgery 1</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card apt-lv-card">
                <div class="card-body px-1">
                  <div class="lv-card-container pt-2 pl-2">
                    <h6 class="d-flex align-items-center text-primary">
                      <i class="el-icon-user mr-2" />
                      {{ getEngagedAppointments[1] || '...' }}
                    </h6>

                    <div class="lv-surgery">
                      <h6>Surgery 2</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card apt-lv-card">
                <div class="card-body px-1">
                  <div class="lv-card-container pt-2 pl-2">
                    <h6 class="d-flex align-items-center text-primary">
                      <i class="el-icon-user mr-2" />
                      {{ getEngagedAppointments[2] || '...' }}
                    </h6>

                    <div class="lv-surgery">
                      <h6>Surgery 3</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card apt-lv-card">
                <div class="card-body px-1">
                  <div class="lv-card-container pt-2 pl-2">
                    <h6 class="d-flex align-items-center text-primary">
                      <i class="el-icon-user mr-2" />
                      {{ getEngagedAppointments[3] || '...' }}
                    </h6>

                    <div class="lv-surgery">
                      <h6>Surgery 4</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card apt-lv-card">
                <div class="card-body px-1">
                  <div class="lv-card-container pt-2 pl-2">
                    <h6 class="d-flex align-items-center text-primary">
                      <i class="el-icon-user mr-2" />
                      {{ nextEngagedAppointment || '...' }}
                    </h6>

                    <div class="lv-surgery">
                      <h6>Next</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </el-card>
      </div>
    </div>

    <el-dialog :visible.sync="showAppointmentForm" class="appointment-create-dialog">
      <div slot="title" class="title-view">
        <span class="el-dialog__title">Add Appointment</span>
        <el-button class="mx-2" size="small" @click="showPatientForm()">New Patient</el-button>
      </div>

      <el-form label-position="left" label-width="130px" :model="appointmentForm" ref="appointmentForm">
        <el-form-item label="Patient" prop="patientName" required>
          <el-autocomplete
            class="inline-input w-100"
            v-model="appointmentForm.patientName"
            :fetch-suggestions="searchPatients"
            :trigger-on-focus="false"
            @select="onPatientSelect"
            prefix-icon="el-icon-search"
            size="small"
            clearable
          >
            <template slot-scope="{ item }">
              <div class="row">
                <div class="col-6">{{ item.value }}</div>
                <div class="col-6">{{ item.contact }}</div>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>

        <el-form-item label="Doctor" prop="doctor" required>
          <el-select v-model="appointmentForm.doctor" placeholder="Select Doctor">
            <el-option-group
              v-for="doctorCategory in doctorCategories"
              :key="doctorCategory.label"
              :label="doctorCategory.label">
              <el-option
                v-for="doctor in doctorCategory.doctors"
                :key="doctor.value"
                :label="doctor.label"
                :value="doctor.value">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>

        <el-form-item label="Date" prop="date" required>
          <el-date-picker type="date" placeholder="Pick a date" format="dd-MM-yyyy" v-model="appointmentForm.date" size="small"></el-date-picker>
        </el-form-item>

        <el-form-item label="Time Duration">
          <el-col :span="11">
            <el-form-item prop="from" required size="mini">
              <vue-timepicker
                v-model="appointmentForm.from"
                format="hh:mm a"
                :minute-interval="30"
                :hour-range="['8a', '9a', '10a', '11a', '12p', ['1p', '9p']]"
                auto-scroll
                input-class="el-input__inner"
                input-width="100%"
              ></vue-timepicker>
            </el-form-item>
          </el-col>

          <el-col class="line text-center" :span="2">To</el-col>

          <el-col :span="11">
            <el-form-item prop="to" required size="mini">
              <vue-timepicker
                v-model="appointmentForm.to"
                format="hh:mm a"
                :minute-interval="30"
                :hour-range="['8a', '9a', '10a', '11a', '12p', ['1p', '9p']]"
                auto-scroll
                input-class="el-input__inner"
                input-width="100%"
              ></vue-timepicker>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item label="Comments">
          <el-input v-model="appointmentForm.comments" size="small" type="textarea"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="discardChanges()">Discard</el-button>
        <el-button type="primary" size="small" @click="onSubmitAppointmentForm()">Save</el-button>
      </span>
    </el-dialog>

    <vue-easy-print ref="printAppointments">
      <table class="el-table el-table--fit el-table--border">
        <thead>
          <tr>
            <th class="text-center border">#</th>
            <th class="text-center border">Patient</th>
            <th class="text-center border">Contact</th>
            <th class="text-center border">Date</th>
            <th class="text-center border">Time</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(printAppointment, index) in printAppointments"
            :key="`printAppointment_${index}`"
            class="el-table__row"
          >
            <td class="px-2">{{ index + 1 }}</td>
            <td class="px-2">{{ printAppointment.patient }}</td>
            <td class="px-2">{{ printAppointment.contact }}</td>
            <td class="px-2">{{ printAppointment.date }}</td>
            <td class="px-2">{{ printAppointment.from }}</td>
          </tr>
        </tbody>
      </table>
    </vue-easy-print>
  </div>
</template>

<script>
import { Card, FormGroupInput as FgInput, Button, DropDown } from '@/components'
import { DatePicker } from 'element-ui'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { getDoctors, getPatients, getAppointments, getAllDoctorAppointments, createAppointment, updateAppointment, deleteAppointment, sendRemindersForTodayAppointments, getFollowUpAppointments } from '@/services/api'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import vueEasyPrint from 'vue-easy-print'
import EventBus from '@/services/event-bus'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    Card,
    DropDown,
    FgInput,
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    FullCalendar,
    vueEasyPrint,
    VueTimepicker,
  },

  data() {
    return {
      patients: [],
      appointments: [],
      followUpAppointments: [],
      appointmentsForDate: moment(),
      viewType: 'calendar',
      doctorFilter: 'all',
      dateRangeFilter: [moment().startOf('month'), moment().endOf('month')],
      dateRangesize: 'small',
      hours: [],
      doctorsWithAppointments: [],
      isAppointmentsLoading: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'timeGridDay',
        dayMaxEvents: true,
        dateClick: this.onDateSelect,
        events: [],
        eventDisplay: 'block',
        displayEventTime: true,
        firstDay: 1,
        allDaySlot: false,
        slotMinTime: '10:00',
        slotMaxTime: '22:00',
      },
      showAppointmentForm: false,
      appointmentForm: {
        patientId: '',
        doctor: '',
        patientName: '',
        date: '',
        from: '',
        to: '',
        comments: '',
      },
      previewAppointment: null,
      engageInSurgery: '',
      printAppointments: [],
      doctorsList: [{
        label: 'Dentist',
        doctors: [],
      }],
      doctorCategories: [{
        label: 'Dentist',
        doctors: [],
      }],
      appointmentsInterval: null,
    }
  },

  created() {
    for(let hour = 8; hour < 22; hour++) {
      this.hours.push(moment({ hour }).format('hh:mm a'))
      this.hours.push(moment({ hour, minute: 30 }).format('hh:mm a'))
    }

    this.isAppointmentsLoading = true
    this.appointmentFormInitialize() // Run immediate on page load
    this.allFollowUpAppointments();

    // Run every 1 minute
    this.appointmentsInterval = window.setInterval(() => {
      this.doctorAppointments()
    }, 60000)

    getAllDoctorAppointments(this.appointmentsForDate)
      .then(response => this.doctorsWithAppointments = response.data)

    getDoctors()
      .then(response =>{
        this.doctorsList[0].doctors.push({ label: 'All', value: 'all' });
        response.data.forEach(doctor => {
          this.doctorCategories[0].doctors.push({ label: doctor.attributes.name, value: doctor.id })
          this.doctorsList[0].doctors.push({ label: doctor.attributes.name, value: doctor.id })
        })
      })
  },

  destroyed() {
    if (this.appointmentsInterval) {
      clearInterval(this.appointmentsInterval);
    }
  },

  computed: {
    ...mapGetters(['getEngagedAppointments', 'nextEngagedAppointment']),
    minStartTime() {
      return moment().format('HH:mm')
    },
    getDoctorsWithAppointments() {
      return this.doctorsWithAppointments
    }
  },

  methods: {
    ...mapActions(['engagePatient', 'engageNextPatient']),
    onDateSelect(args) {
      if (!moment(args.date).isBefore(moment(), "day")) {
        this.showAppointmentForm = true
        this.appointmentForm.date = args.dateStr
        this.appointmentForm.from = moment(args.date).format('hh:mm a'); // e.g: "10:00 am"
        this.appointmentForm.to = moment(args.date).add(30, 'minutes').format('hh:mm a')
      } else {
        this.$message.error('Unable to create appointment on previous dates.');
      }
    },
    appointmentFormInitialize() {
      getAppointments({ q: { status_in: ['confirmed', 'arrived', 'engaged', 'follow_up'] } })
        .then(response => {
          this.isAppointmentsLoading = false
          this.calendarOptions.events = []
          this.appointments = response.data
          response.data.forEach(appointment => {
            let patient_name = appointment.attributes.patient.data.attributes.name;
            let patient_phone = appointment.attributes.patient.data.attributes.phone;
            let doctor_name = "";
            if (appointment.attributes.patient.data.attributes.doctor.data) {
              doctor_name = appointment.attributes.patient.data.attributes.doctor.data.attributes.name;
            }

            this.calendarOptions.events.push({
              id: appointment.id,
              patientName: patient_name,
              patientPhone: `+92 ${patient_phone}`,
              doctorName: doctor_name,
              comment: appointment.attributes.comments,
              start: appointment.attributes.from,
              end: appointment.attributes.to,
              allDay: false,
              color: this.eventColorFor(appointment),
            })
          })
          this.setPrintAppointments()
        })
    },
    allFollowUpAppointments() {
      getFollowUpAppointments()
        .then(response => {
          this.followUpAppointments = response.data
        })
    },
    setPreviewAppointment(appointmentId) {
      this.previewAppointment = this.appointments.find(appointment => appointment.id == appointmentId)
    },
    searchPatients(query, resolve) {
      getPatients({ q: { name_or_phone_cont: query } })
        .then(response => {
          let patients = query ? response.data.map(patient => ({ value: patient.attributes.name, id: patient.id, contact: patient.attributes.phone })) : []
          resolve(patients)
        })
    },
    onPatientSelect(patient) {
      this.appointmentForm.patientName = patient.value
      this.appointmentForm.patientId = patient.id
    },
    onSubmitAppointmentForm() {
      this.$refs['appointmentForm'].validate(valid => {
        if (!valid) return false

        let fromTimeWithAmPm = this.appointmentForm.from.split(' ')
        let fromHoursAndMinutes = fromTimeWithAmPm[0].split(':')
        let fromHours = parseInt(fromHoursAndMinutes[0])
        let fromMinutes = parseInt(fromHoursAndMinutes[1])

        if (fromTimeWithAmPm[1] == 'pm' && fromHours < 12) fromHours += 12
        if (fromTimeWithAmPm[1] == 'am' && fromHours == 12) fromHours -= 12

        let toTimeWithAmPm = this.appointmentForm.to.split(' ')
        let toHoursAndMinutes = toTimeWithAmPm[0].split(':')
        let toHours = parseInt(toHoursAndMinutes[0])
        let toMinutes = parseInt(toHoursAndMinutes[1])

        if (toTimeWithAmPm[1] == 'pm' && toHours < 12) toHours += 12
        if (toTimeWithAmPm[1] == 'am' && toHours == 12) toHours -= 12

        let params = {
          patient_id: this.appointmentForm.patientId,
          doctor_id: this.appointmentForm.doctor,
          appointment: {
            appointment_on: moment(this.appointmentForm.date).format('DD-MM-YYYY'),
            from: moment(this.appointmentForm.date).set({ hours: fromHours, minutes: fromMinutes }).utc(),
            to: moment(this.appointmentForm.date).set({ hours: toHours, minutes: toMinutes }).utc(),
            comments: this.appointmentForm.comments,
            status: 'confirmed',
          }
        }

        createAppointment(params)
          .then(response => {
            let doctor_name = "";
            if (response.data.attributes.patient.data.attributes.doctor.data) {
              doctor_name = response.data.attributes.patient.data.attributes.doctor.data.attributes.name;
            }
            this.calendarOptions.events.push({
              id: response.data.id,
              patientName: response.data.attributes.patient.data.attributes.name,
              patientPhone: `+92 ${response.data.attributes.patient.data.attributes.phone}`,
              doctorName: doctor_name,
              comment: response.data.attributes.comments,
              start: response.data.attributes.from,
              end: response.data.attributes.to,
              allDay: false,
              color: '#67C23A',
            })
            this.appointments.push(response.data)
            this.$refs['appointmentForm'].resetFields();
            this.discardChanges()
          })
      });
    },
    discardChanges() {
      this.showAppointmentForm = false
      this.clearAppointmentForm()
    },
    clearAppointmentForm() {
      this.appointmentForm = {
        patientId: '',
        doctor: '',
        patientName: '',
        date: '',
        from: '',
        to: '',
        comments: '',
      }
    },
    convertUtcToLocal(timeInUtc) {
      let utcFormat = moment.utc(timeInUtc)
      return moment.utc(utcFormat).local().format('hh:mm a')
    },
    appointmentStartTime(appointmentStartTime) {
      return moment(appointmentStartTime).format('hh:mma')
    },
    formatAppointments() {
      this.setPrintAppointments()
      if (this.viewType == 'calendar') return
      getAllDoctorAppointments(this.appointmentsForDate)
        .then(response => this.doctorsWithAppointments = response.data)
      if (this.hours.length >= 0) return
    },
    doctorAppointments() {
      let start_date = moment().startOf('month').format("DD-MM-yyyy");
      let end_date = moment().endOf('month').format("DD-MM-yyyy");
      if (this.dateRangeFilter) {
        start_date = moment(this.dateRangeFilter[0]).format("DD-MM-yyyy");
        end_date = moment(this.dateRangeFilter[1]).format("DD-MM-yyyy");
      } else {
        this.dateRangeFilter = [moment().startOf('month'), moment().endOf('month')];
      }

      this.isAppointmentsLoading = true
      let params = {
        q: {
          status_in: ['confirmed', 'arrived', 'engaged', 'follow_up']
        },
        doctor_id: this.doctorFilter,
        start_date: start_date,
        end_date: end_date
      }
      getAppointments(params)
        .then(response => {
          this.isAppointmentsLoading = false
          this.calendarOptions.events = []
          this.appointments = response.data
          response.data.forEach(appointment => {
            let patient_name = appointment.attributes.patient.data.attributes.name;
            let patient_phone = appointment.attributes.patient.data.attributes.phone;
            let doctor_name = "";
            if (appointment.attributes.patient.data.attributes.doctor.data) {
              doctor_name = appointment.attributes.patient.data.attributes.doctor.data.attributes.name;
            }

            this.calendarOptions.events.push({
              id: appointment.id,
              patientName: patient_name,
              patientPhone: `+92 ${patient_phone}`,
              doctorName: doctor_name,
              comment: appointment.attributes.comments,
              start: appointment.attributes.from,
              end: appointment.attributes.to,
              allDay: false,
              color: this.eventColorFor(appointment),
            })
          })
          this.setPrintAppointments()
        })
    },
    getAppointmentForDoctor(index, timeSlot) {
      let doctorAppointment = null

      this.doctorsWithAppointments[index].attributes.appointments.data.forEach(appointment => {
        if (timeSlot == moment(appointment.attributes.from).format('hh:mm a')) {
          doctorAppointment = appointment
          return
        }
      });

      return doctorAppointment
    },
    updateAppointmentStatus(status) {
      if (this.previewAppointment != null)
        updateAppointment(this.previewAppointment.id, { appointment: { status: status } })
          .then(response => {
            this.previewAppointment = response.data;
            if (status == 'follow_up') {
              this.allFollowUpAppointments()
            }
          })
          .catch(error => console.log(error))

      if (status == 'engaged')
        this.engagePatient({ index: this.engageInSurgery, patientName: this.previewAppointment.attributes.patient.data.attributes.name })
      if (status == 'engaged' && this.engageInSurgery == 4)
        this.engageNextPatient(this.previewAppointment.attributes.patient.data.attributes.name)
      if (status == 'arrived')
        this.calendarOptions.events.find(event => event.id == this.previewAppointment.id).color = '#67C23A'
      if (status == 'cancelled')
        this.calendarOptions.events.find(event => event.id == this.previewAppointment.id).color = '#F56C6C'
      if (status == 'follow_up')
        this.calendarOptions.events.find(event => event.id == this.previewAppointment.id).color = '#909399'
      this.engageInSurgery = ''
    },
    deleteFollowUpAppointment(appointment_id) {
      if (appointment_id ) {
        deleteAppointment(appointment_id)
          .then(response => this.followUpAppointments.splice(this.followUpAppointments.findIndex(a => a.id === appointment_id) , 1))
          .catch(error => console.log(error))
      }
    },
    sendReminders() {
      // Access the FullCalendar instance
      const calendar = this.$refs.fullCalendar.getApi();

      // Get the calendar title (current date) and type
      const {title, type} = calendar.view;

      if (title && type == 'timeGridDay') {
        // send reminder
        sendRemindersForTodayAppointments({date: title})
        .then(response => {
          this.$message({
            showClose: true,
            message: 'Reminders sent successfully!',
            type: 'success'
          });
        })
        .catch(error => console.log(error))
      } else {
        this.$message({
          showClose: true,
          message: "Reminder can only be sent for a single day.",
          type: 'error'
        });
      }
    },
    openFullScreen() {
      this.$router.push({ path: 'live-view' })
    },
    setPrintAppointments() {
      this.printAppointments = []

      this.appointments.filter(appointment => moment(appointment.attributes.appointment_on).format('L') == moment(this.appointmentsForDate).format('L')).forEach(appointment => {
        this.printAppointments.push({
          patient: appointment.attributes.patient.data.attributes.name,
          contact: appointment.attributes.patient.data.attributes.phone,
          date: appointment.attributes.appointment_on,
          from: moment(appointment.attributes.from).format('hh:mm A'),
        })
      });
    },
    onPrintAppointments() {
      if (this.printAppointments.length > 0)
        this.$refs.printAppointments.print()
      else
        this.$message.error('There are no appointments for the selected date.');
    },
    showPatientForm() {
      EventBus.$emit('onAddPatientClick', true)
    },
    eventColorFor(appointment) {
      if (appointment.attributes.status == 'cancelled') return '#F56C6C'
      if (appointment.attributes.status == 'arrived') return '#67C23A'
      if (appointment.attributes.status == 'confirmed') return '#00AEF0'
      if (appointment.attributes.status == 'follow_up') return '#909399'
    }
  }
};
</script>

<style lang='scss'>
.full-calendar-row .fc-toolbar {
  display: flex;
}
.appointment-create-dialog {
  .el-dialog {
    width: 60%;
  }
}
/* Apply display: block on small devices */
@media (max-width: 767px) {
  .full-calendar-row {
    .fc-toolbar {
        display: block;
    }
    .fc-toolbar-chunk {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
    }
  }

  .appointment-create-dialog {
    width: 100%;

    .el-dialog {
      width: 90%;
    }

    .title-view {
      display: inline-grid;

      button {
        margin-top: 8px;
      }
    }
  }
}
.colors-container {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  .status-color {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 0.2em;
    border-radius: 3px;
  }
  .booked {
    background-color: #00AEF0;
  }
  .patient-arrived {
    background-color: #67C23A;
  }
  .cancelled {
    background-color: #F56C6C;
  }
}
.patient-name {
  color: #444444 !important;
  font-size: 16px;
}
.patient-contact {
  font-size: 13px;
  color: #444444 !important;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-normal {
  font-weight: normal;
}
.font-weight-500 {
  font-weight: 500;
}
.font-18 {
  font-size: 18px;
}
.full-calendar-row {
  .fc-event-main {
    .font-13 {
      font-size: 13px;
    }
  }
  h2 {
  	margin: 0;
  	font-size: 16px;
  }
  ul {
  	margin: 0;
  	padding: 0 0 0 1.5em;
  }
  li {
  	margin: 1.5em 0;
  	padding: 0;
  }
  b {
  	margin-right: 3px;
  }
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #00AEF0;
}
.full-calendar-row .fc-timegrid-slots tr {
  height: 100px;
}
.full-calendar-row .fc-view-harness {
  height: 2430px !important;
}
</style>
