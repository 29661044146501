<template>
  <el-dialog :visible.sync="showAppointmentForm" width="50%" @close="discardChanges()">
    <div slot="title">
      <span class="el-dialog__title">Add Appointment</span>
      <!-- <el-button class="mx-2" size="small" @click="showPatientForm()">New Patient</el-button> -->
    </div>

    <el-form label-position="left" label-width="130px" :model="appointmentForm" ref="appointmentForm">
      <el-form-item label="Patient" prop="patientName" required>
        <el-autocomplete
          class="inline-input w-100"
          v-model="appointmentForm.patientName"
          :fetch-suggestions="searchPatients"
          :trigger-on-focus="false"
          @select="onPatientSelect"
          prefix-icon="el-icon-search"
          size="small"
          clearable
        >
          <template slot-scope="{ item }">
            <div class="row">
              <div class="col-6">{{ item.value }}</div>
              <div class="col-6">{{ item.contact }}</div>
            </div>
          </template>
        </el-autocomplete>
      </el-form-item>

      <el-form-item label="Date" prop="date" required>
        <el-date-picker type="date" placeholder="Pick a date" format="dd-MM-yyyy" v-model="appointmentForm.date" size="small"></el-date-picker>
      </el-form-item>

      <el-form-item label="Time Duration">
        <el-col :span="11">
          <el-form-item prop="from" required size="mini">
            <vue-timepicker
              v-model="appointmentForm.from"
              format="hh:mm a"
              :minute-interval="30"
              :hour-range="['8a', '9a', '10a', '11a', '12p', ['1p', '9p']]"
              auto-scroll
              input-class="el-input__inner"
              input-width="100%"
            ></vue-timepicker>
          </el-form-item>
        </el-col>

        <el-col class="line text-center" :span="2">To</el-col>

        <el-col :span="11">
          <el-form-item prop="to" required size="mini">
            <vue-timepicker
              v-model="appointmentForm.to"
              format="hh:mm a"
              :minute-interval="30"
              :hour-range="['8a', '9a', '10a', '11a', '12p', ['1p', '9p']]"
              auto-scroll
              input-class="el-input__inner"
              input-width="100%"
            ></vue-timepicker>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item label="Comments">
        <el-input v-model="appointmentForm.comments" size="small" type="textarea"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="discardChanges()">Discard</el-button>
      <el-button type="primary" size="small" @click="onSubmitAppointmentForm()">Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getPatients, createAppointment } from '@/services/api'
  import moment from 'moment'
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'

  export default {
    components: {
      VueTimepicker,
    },

    data() {
      return {
        showAppointmentForm: false,
        appointmentForm: {
          patientId: '',
          patientName: '',
          date: '',
          from: '',
          to: '',
          comments: '',
        },
      }
    },

    methods: {
      show(patient) {
        this.showAppointmentForm = true
        this.appointmentForm.patientId = patient.id
        this.appointmentForm.patientName = patient.attributes.name
      },
      searchPatients(query, resolve) {
        getPatients({ q: { name_or_phone_cont: query } })
          .then(response => {
            let patients = query ? response.data.map(patient => ({ value: patient.attributes.name, id: patient.id, contact: patient.attributes.phone })) : []
            resolve(patients)
          })
      },
      onPatientSelect(patient) {
        this.appointmentForm.patientName = patient.value
        this.appointmentForm.patientId = patient.id
      },
      onSubmitAppointmentForm() {
        this.$refs['appointmentForm'].validate(valid => {
          if (!valid) return false

          let fromTimeWithAmPm = this.appointmentForm.from.split(' ')
          let fromHoursAndMinutes = fromTimeWithAmPm[0].split(':')
          let fromHours = parseInt(fromHoursAndMinutes[0])
          let fromMinutes = parseInt(fromHoursAndMinutes[1])

          if (fromTimeWithAmPm[1] == 'pm' && fromHours < 12) fromHours += 12
          if (fromTimeWithAmPm[1] == 'am' && fromHours == 12) fromHours -= 12

          let toTimeWithAmPm = this.appointmentForm.to.split(' ')
          let toHoursAndMinutes = toTimeWithAmPm[0].split(':')
          let toHours = parseInt(toHoursAndMinutes[0])
          let toMinutes = parseInt(toHoursAndMinutes[1])

          if (toTimeWithAmPm[1] == 'pm' && toHours < 12) toHours += 12
          if (toTimeWithAmPm[1] == 'am' && toHours == 12) toHours -= 12

          let params = {
            patient_id: this.appointmentForm.patientId,
            appointment: {
              appointment_on: moment(this.appointmentForm.date).format('DD-MM-YYYY'),
              from: moment(this.appointmentForm.date).set({ hours: fromHours, minutes: fromMinutes }).utc(),
              to: moment(this.appointmentForm.date).set({ hours: toHours, minutes: toMinutes }).utc(),
              comments: this.appointmentForm.comments,
              status: 'confirmed',
            }
          }

          createAppointment(params)
            .then(response => {
              this.$message({
                message: 'Appointment was saved successfully.',
                type: 'success'
              });
              this.$refs['appointmentForm'].resetFields();
              this.discardChanges()
            })
        });
      },
      discardChanges() {
        this.showAppointmentForm = false
        this.clearAppointmentForm()
        this.$refs['appointmentForm'].resetFields();
      },
      clearAppointmentForm() {
        this.appointmentForm = {
          patientId: '',
          patientName: '',
          date: '',
          from: '',
          to: '',
          comments: '',
        }
      },
    }
  }
</script>
